import { Subject } from 'store/common/types';
import { getFirstName, placesToGenitive, weekDayToAccusative } from './utils';

const placesLeftMessage = (places: number, placesLeft: number) => `осталось ${placesLeft} из ${places} ${placesToGenitive(places)}`;

export const translations = {
    buySubscription: 'Оплатить марафон',
    scheduleMenu: '📆 Расписание',
    bookingMenu: '🎓 Запись',
    marathonMenu: '🏃‍♀️ Марафон',
    logout: '🚪 Выйти',
    loading: 'Загрузка...',
    bookLesson: '🎓 Записаться на урок',
    bookLessonDisabledTooltipLine1: 'Вы успешно записались на уроки по всем предметам 🎉 ',
    bookLessonDisabledTooltipLine2: 'Запись откроется снова, когда вы посетите текущие уроки 😉',
    mySchedule: 'Мое расписание',
    allSubjects: 'Все',
    bookingDescription: (teacherName: string, places: number, placesLeft: number, subject?: Subject) =>
        `${getFirstName(teacherName)}, ${placesLeftMessage(places, placesLeft)} ${subject ? `/ ${subject.title} ${subject.emoji}` : ''}`,
    detailsButton: 'Подробнее',
    dialogTitle: '🎉 Мы ждем вас на уроке',
    dialogVkSubscription: '❗ Нажми на эту кнопку, чтобы включить оповещения через ВКонтакте и получить ссылку для входа на урок',
    dialogLessonInfo: (weekDay: string, daysOfMonth: string, month: string, time: string, topic: string) =>
        `Преподаватель будет ждать вас ${weekDayToAccusative(weekDay)} ${daysOfMonth} ${month} в ${time} на уроке по теме «${topic}»`,
    dialogPlacesLeft: (teacherName: string, places: number, placesLeft: number) =>
        `${getFirstName(teacherName)}, ${placesLeftMessage(places, placesLeft)}`,
    dialogLinkWillAppear: 'Ссылка для входа появится здесь за 10 минут до начала урока.',
    dialogCancelBooking: 'Отменить запись на урок',
    dialogJoinLesson: 'Присоединиться к уроку',
    dialogGetReady: 'Подготовьтесь ко встрече',
    dialogInstructionLine1Before: '1. Подготовьтесь к уроку – прочитайте ',
    dialogInstructionLine1Link: 'рекомендации',
    dialogInstructionLine2: '2. Проверьте WhatsApp – туда придет ссылка для входа на занятие',
    dialogInstructionLine3: '3. Дождитесь начала урока 😉',
    dialogDownloadButton: 'Скачать Zoom',

    lessonBooking: 'Выберите предмет, дату и время урока',
    lessonMySchedule: '📖 Мое расписание',
    subjectBookedEmoji: '✅',
    bookLessonButton: 'Записаться',
    bookedDialogTitle: '🎉 Вы записались на урок',
    noDataPlaceholderText: '😕 В этот день по данному предмету занятий пока нет',
    noDataSearchTextPart1: '😕 К сожалению по теме ',
    noDataSearchTextPart2: (topic: string) => `«${topic}»`,
    noDataSearchTextPart3: ' пока нет уроков. А пока ты можешь найти ближайшие ',
    noDataSearchTextPart4: '🎓 записи',
    noDataSearchTextPart5: ' или вернуться к ',
    noDataSearchTextPart6: '🏃‍♀️ марафону',
    backToMarathon: 'Обратно к 🏃‍♀️ марафону',
    createLessonButton: 'Создать урок',
    pastLessonsButton: '🏁 Проведенные уроки',
    pastLessonsHeader: 'Проведенные уроки',
    editLessonButton: 'Редактировать',

    graduationCapEmoji: '🎓',
    createLessonHeader: 'Создание урока',
    editLessonHeader: 'Редактирование урока',
    subjectPlaceholder: 'Предмет',
    topicPlaceholder: 'Тема урока',
    descriptionPlaceholder: 'Короткое описание урока',
    datePlaceholder: 'Дата и время урока',
    cancelLabel: 'ОТМЕНА',
    okLabel: 'ОК',
    linkPlaceholder: 'Ссылка на встречу',
    saveButton: 'Сохранить',
    editCancelButton: 'Отменить урок',
    saveClickDisclaimer: 'Когда вы нажмете на кнопку сохранить, ваш урок появится на платформе и ученики смогут на него записаться.',

    subscriptionHeader: 'Занимайтесь с живыми преподавателями, пока не сдадите ЕГЭ',
    subscriptionSubheader:
        'Оплатите марафон и занимайтесь хоть каждый день. Никаких скрытых условий на количество посещений. Вы ограничены только своим свободным временем 😉',
    subscriptionExpiresAt: (day: string, month: string) => `Доступ до ${day} ${month}`,
    subscriptionPrice: (price: number) => `${price} ₽`,
    subscriptionPriceAMonth: (price: string) => `${price} в месяц`,
    subscriptionBuyFor: (price: string) => `Купить за ${price}`,
    subscriptionDisclaimer:
        'По нажатию на кнопку вы будете направлены в наш интернет-магазин ВКонтакте, где сможете оформить заказ на подписку. После этого вам напишет менеджер, ответит на вопросы и примет оплату 🚀',
    subscriptionInactive: 'Подписка истекла',

    tariffLeftRow1: 'Живые преподаватели, а не записанные видео',
    tariffLeftRow2: 'Занятия онлайн, занимайтесь где вам удобно',
    tariffLeftRow3: 'Уютный чат ВКонтакте по каждому предмету',
    tariffLeftRow4: 'Чат-боты для ежедневных тренировок',

    tariffRightRow1: 'Профильная математика',
    tariffRightRow2: 'Русский язык',
    tariffRightRow3: 'Обществознание',
    tariffRightRow4: 'Физика',

    tariffCentralHeader: 'Доступ до конца июня',
    tariffCentralSubHeader: 'Всего по 57 рублей в день',
    tariffCentralButton: 'Купить за 5670 рублей',

    loginHeader: 'Вход',
    loginButton: 'Войти',
    loginPlaceholder: 'Номер телефона',
    passwordPlaceholder: 'Пароль',
    notRegistered: 'Впервые с нами?',
    registerLink: 'Зарегистрируйся',
    loginError: 'Неправильный номер или пароль',

    registerHeader: 'Регистрация',
    registerButton: 'Зарегистрироваться',
    registerPhonePlaceholder: 'Номер телефона',
    registerEmailPlaceholder: 'E-mail',
    registerTimezonePlaceholder: 'Временная зона',
    registerNamePlaceholder: 'Полное имя',
    registerPasswordPlaceholder: 'Пароль',
    registerPasswordRepeatPlaceholder: 'Повторите пароль',
    uploadAvatar: 'Загрузить аватар',
    validation: {
        email: 'Неправильный формат e-mail',
        pass: 'Пароль должен быть не менее 8 символов',
        pass2: 'Пароли не совпадают',
    },

    chooseSubjectsHeader: 'К каким предметам ты готовишься?',
    chooseSubjectsButton: '💪 Далее ->',
};
