import * as React from 'react'
import { Grid, useMediaQuery, Theme, makeStyles } from '@material-ui/core'
import { splitDateTime } from 'utils/helpers'
import { BookingTimeslotContainer, TimeslotText } from './style'

const useStyles = makeStyles({
  widthAuto: {
    width: 'auto'
  },
  height100: {
    height: '100%'
  },
  boldText: { fontSize: '18px', fontWeight: 500 },
  marginLeft: { marginLeft: 5 }
})

export interface BookingTimeslotProps {
  date: Date
}

export const DateBookingTimeslot: React.SFC<BookingTimeslotProps> = ({ date }) => {
  const { month, dayOfMonth, weekDay, time } = splitDateTime(date)
  const classes = useStyles()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <BookingTimeslotContainer>
      {mobile ? (
        <>
          <Grid container justify="space-between">
            <Grid className={classes.widthAuto} container justify="flex-start" alignItems="center">
              <TimeslotText className={classes.boldText}>{time}</TimeslotText>
              <TimeslotText className={classes.marginLeft}>{weekDay}</TimeslotText>
            </Grid>
            <Grid className={classes.widthAuto} container justify="flex-end" alignItems="center">
              <TimeslotText className={classes.boldText}>{dayOfMonth}</TimeslotText>
              <TimeslotText className={classes.marginLeft}>{month}</TimeslotText>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justify="space-between">
            <TimeslotText className={classes.boldText}>{dayOfMonth}</TimeslotText>
            <TimeslotText>{weekDay}</TimeslotText>
          </Grid>
          <TimeslotText>{month}</TimeslotText>
          <TimeslotText>
            {time}{' '}
            <span role="img" aria-label="clock">
              ⏰
            </span>
          </TimeslotText>
        </>
      )}
    </BookingTimeslotContainer>
  )
}

export const TimeOnlyBookingTimeslot: React.SFC<BookingTimeslotProps> = ({ date }) => {
  const { time } = splitDateTime(date)
  const classes = useStyles()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  return (
    <BookingTimeslotContainer>
      <Grid
        className={mobile ? classes.widthAuto : classes.height100}
        container
        justify={mobile ? 'flex-start' : 'center'}
        alignItems="center"
      >
        <TimeslotText className={classes.boldText}>{time}</TimeslotText>
      </Grid>
    </BookingTimeslotContainer>
  )
}
