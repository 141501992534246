import { createSelector } from 'reselect'
import { expired } from 'utils/helpers'
import { ApplicationState } from '..'
import { isLoggedIn } from './types'

export const selectAccount = createSelector(
  (state: ApplicationState) => state.account.login,
  account => account
)

export const selectSubjects = createSelector(
  (state: ApplicationState) => state.account.subjects,
  subjects => subjects.data.subjects
)

export const selectAvatarRegistration = createSelector(
  (state: ApplicationState) => state.account.registration,
  registration => registration.avatar
)

export const selectAccountContext = createSelector(
  (state: ApplicationState) => state.account.context,
  context => context
)

export const selectLogoutLink = createSelector(selectAccountContext, context => context.logoutLink)

export const selectSubscriptionIsActive = createSelector(selectAccount, acc => {
  if (!isLoggedIn(acc.data)) return false
  return !expired(acc.data.info.subscriptionExpiresAt)
})

export const selectRegistrationFailed = createSelector(
  (state: ApplicationState) => state.account.registration,
  registration => registration.registrationFailed !== undefined && registration.registrationFailed
)

export const selectIsRegistrationFinished = createSelector(
  (state: ApplicationState) => state.account.registration,
  registration => registration.isRegistrationFinished
)
