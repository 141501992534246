import React from 'react'
import { Loading } from './Loading'

interface LoadingSuspenseProps {
  loading: boolean
  loadingComponent?: React.ReactNode
}

export const LoadingSuspense: React.FC<LoadingSuspenseProps> = ({ loading, loadingComponent, children }) => {
  return <>{loading ? loadingComponent || <Loading /> : children}</>
}
