export const recommendationLink = 'https://nexted.slite.com/api/s/note/WkTiJVKk2BypJNHP9mSDpi/Rekomendacii';
export const marathonTariffLink = 'https://vk.com/nextedexam?w=product-184633763_3387718';
export const downloadZoomLink = 'https://zoom.us/download';
export const maxDisplayedDates = 7;
export const maxDisplayedDatesMobile = 3;
export const maxTopicLength = 70;
export const maxDescriptionLength = 170;
export const phonePrefix = '+7';
export const phoneLength = 10;
export const maxSubjectsToSelect = 4;

export enum PageUrls {
    TeacherPrefix = '/teacher',
    TeacherSchedule = '/teacher',
    TeacherCreate = '/teacher/create',
    TeacherEdit = '/teacher/edit',
    TeacherPastLessons = '/teacher/pastLessons',
    TeacherApiSchedule = '/teacher/schedule',
    TeacherApiPastLessons = '/teacher/lessons/finished',
    TeacherApiSubjects = '/teacher/subjects',
    TeacherApiCreate = '/teacher/lessons',
    TeacherApiGetLesson = '/teacher/lessons',
    TeacherApiEdit = '/teacher/edit',

    SchedulePrefix = '/schedule',
    ScheduleIndex = '/schedule',
    ScheduleBooking = '/schedule/booking',
    ScheduleSearch = '/schedule/search',
    ScheduleApiIndex = '/student/schedule',
    ScheduleApiBooking = '/student/lessons/current',
    ScheduleApiSearch = '/student/lessons/search',
    ScheduleApiCreateBooking = '/student/bookings',

    SubscriptionPrefix = '/subscription',
    SubscriptionApi = '/student/tariffs',
    Subscription = '/subscription',

    Login = '/login/:phoneParam?',
    LoginNoParams = '/login',
    LoginTeacher = '/loginTeacher',
    Register = '/register/:phoneParam?',
    RegisterNoParams = '/register',
    ChooseSubjects = '/subjects',
    Marathon = '/marathon',
    AccountApiPrefix = '/onboarding',
    LoginApi = '/student/login',
    LoginPhoneApi = '/student/phone-login',
    LoginTeacherApi = '/teacher/login',
    RegisterApi = '/student/registration',
    LoadUserDataApi = '/common/me',
    UploadAvatarApi = '/common/avatar',
    GetAvatarApi = '/internal/files',
    ChooseSubjectsApi = '/student/subjects',
    SubmitSubjectsApi = '/student/update',
}
