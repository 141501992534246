import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'
import { PageUrls } from 'utils/constants'

import { fetchRequest } from 'store/schedule/actions'
import { translations } from 'i18n/rus'
import { StyledButton, StyledLink, PanelGrid } from 'components/common'
import { Grid, ClickAwayListener } from '@material-ui/core'
import { selectScheduleIndexPageState } from 'store/schedule/selectors'

import { DetailsDialog } from 'components/schedule/dialog/DetailsDialog'
import ScheduleSubjectTabs from 'components/schedule/subjectTabs/schedule'
import { Loading } from 'components/common/data/Loading'
import { StyledH2, StyledTooltip } from './style'

const LessonsBookedTooltip: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <StyledTooltip
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          title={
            <>
              <p>{translations.bookLessonDisabledTooltipLine1}</p>
              <p>{translations.bookLessonDisabledTooltipLine2}</p>
            </>
          }
          arrow
        >
          <span>
            <StyledButton onClick={handleTooltipOpen}>{translations.bookLesson}</StyledButton>
          </span>
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  )
}

const ScheduleIndexPage: React.FC = () => {
  const dispatch = useDispatch()
  const { loading, data } = useSelector(selectScheduleIndexPageState)

  React.useEffect(() => {
    dispatch(fetchRequest())
  }, [dispatch])

  return (
    <Page>
      <Container>
        <Grid alignItems="center" direction="column" container>
          {data.allSubjectsAreBooked ? (
            <LessonsBookedTooltip />
          ) : (
            <StyledLink tabIndex={-1} to={PageUrls.ScheduleBooking}>
              <StyledButton>{translations.bookLesson}</StyledButton>
            </StyledLink>
          )}
          <StyledH2>{translations.mySchedule}</StyledH2>
          {loading && <Loading />}
          <PanelGrid>
            <ScheduleSubjectTabs
              subjectIds={data.subjectIds}
              subjectsById={data.subjectsById}
              lessonsBySubjectId={data.lessonsBySubjectId}
              teacherById={data.teachersById}
            />
            <DetailsDialog />
          </PanelGrid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ScheduleIndexPage
