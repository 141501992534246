import moment from 'moment'
import { Lesson } from 'store/common/types'

export interface DateTimeOptions {
  weekday: string
  month: string
  day: string
  hour: string
  minute: string
}

const defaultSplitDateTimeOptions = {
  weekday: 'short',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
}

export const splitDateTime = (date: Date, options?: Partial<DateTimeOptions>) => {
  // TODO load locale from the server
  const [weekDay, day, time] = date
    .toLocaleDateString('ru-RU', { ...defaultSplitDateTimeOptions, ...options })
    .split(',')
    .map(i => i.trim())
  const [dayOfMonth, month] = day.split(' ')

  return { month, dayOfMonth, weekDay, time }
}

export const getNameInitials = (fullName: string) => {
  return fullName
    ? fullName
        .split(' ')
        .slice(0, 2)
        .map(n => n[0])
        .concat()
    : ''
}

export const addDays = (date: Date, days: number): Date => {
  return moment(date)
    .add(days, 'days')
    .toDate()
}

export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000)
}

export function* range(n: number): IterableIterator<number> {
  let i = 0
  while (i < n) {
    yield i++
  }
}

export const withoutTime = (date: Date) => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

export const getToday = () => withoutTime(new Date())
export const getInMonth = () => {
  const today = getToday()
  return withoutTime(new Date(today.setMonth(today.getMonth() + 1)))
}

export const compareDates = (d1: Date, d2: Date) => {
  return d1.getTime() === d2.getTime()
}

export function validZoomURL(str: string) {
  const pattern = /^https:\/\/(zoom.us\/j\/\d+)|(whereby.com\/.+)$/
  return pattern.test(str)
}

export const removeSpaces = (s: string) => s.replace(/\s/g, '')

export const removeSpacesAndUnderlines = (s: string) => s.replace(/\s|_/g, '')

export const validateEmail = (value: string): boolean => value !== undefined && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

export const toUtc = (d: Date) => moment.utc(d)
export const toLocal = (v: string) =>
  moment(v)
    .local()
    .toDate()

export const compareIgnoringTime = (a: Date, b: Date) => moment(a).isSame(b, 'day')
export const dateKeyWithoutTime = (a: Date) => moment(a).format('YYYYMMDD')
export const dayDiffWithToday = (a: Date) => moment(a).diff(moment().startOf('day'), 'days')

export function reviver(key: any, value: any) {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return toLocal(value)
  }

  return value
}

export const lessThan10mLeft = (time: Date) => moment().isAfter(moment(time).subtract(10, 'minutes'))

export const expired = (subscriptionExpiresAt: Date) => moment().isAfter(moment(subscriptionExpiresAt))

export const tokenExists = () => localStorage.token !== undefined

export const lessonSorter = (a: Lesson, b: Lesson) => a.scheduledTime.getTime() - b.scheduledTime.getTime()
export const lessonsSorterReversed = (a: Lesson, b: Lesson) => lessonSorter(b, a)

export const arrayToDictionary = <T>(reducer: (s: T) => any, array: T[]): Record<number | string, T> =>
  array.reduce((obj: Record<number | string, T>, item: T) => {
    obj[reducer(item)] = item
    return obj
  }, {})
