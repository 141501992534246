import { MarathonMap } from '../types'

export const math: MarathonMap = {
  subject: 'Математика профильная',
  goals: [
    {
      title: '💪 Научись решать базовые задания',
      targets: [
        {
          lessonTitle: 'Задание 1. Простейшие текстовые задачи',
          mark: 1
        },
        {
          lessonTitle: 'Задание 2. Графики и диаграммы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 3. Геометрические фигуры, координаты, векторы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 4. Начала теории вероятностей',
          mark: 1
        },
        {
          lessonTitle: 'Задание 5. Уравнения и неравенства',
          mark: 1
        }
      ]
    },
    {
      title: '📐 Подготовься к среднему уровню',
      targets: [
        {
          lessonTitle: 'Задание 6. Планиметрия',
          mark: 1
        },
        {
          lessonTitle: 'Задание 7. Производная и первообразная',
          mark: 1
        },
        {
          lessonTitle: 'Задание 8. Стереометрия',
          mark: 1
        },
        {
          lessonTitle: 'Задание 9. Вычисления и преобразования',
          mark: 1
        },
        {
          lessonTitle: 'Задание 10. Задачи с прикладным содержанием',
          mark: 1
        },
        {
          lessonTitle: 'Задание 11. Текстовые задачи',
          mark: 1
        },
        {
          lessonTitle: 'Задание 12. Наибольшее и наименьшее значение функций',
          mark: 1
        }
      ]
    },
    {
      title: '🎓 Покажи, что готов поступать в вуз',
      targets: [
        {
          lessonTitle: 'Задание 13. Уравнения',
          mark: 2
        },
        {
          lessonTitle: 'Задание 14. Стереометрическая задача',
          mark: 2
        },
        {
          lessonTitle: 'Задание 15. Неравенства',
          mark: 2
        },
        {
          lessonTitle: 'Задание 16. Планиметрическая задача',
          mark: 3
        },
        {
          lessonTitle: 'Задание 17. Финансовая математика',
          mark: 3
        }
      ]
    },
    {
      title: '🔮 Заверши обучение – пойми сложнейшие задания',
      targets: [
        {
          lessonTitle: 'Задание 18. Задача с параметром',
          mark: 4
        },
        {
          lessonTitle: 'Задание 19. Числа и их свойства',
          mark: 4
        }
      ]
    }
  ]
}
