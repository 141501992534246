import { styled, withStyles, Theme, Tooltip } from '@material-ui/core'

export const StyledH2 = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '1.2em',
  fontWeight: 500,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '1.5em'
  }
}))

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    background: theme.colors.tooltip,
    textAlign: 'center',
    fontSize: 14
  },
  arrow: {
    color: theme.colors.tooltip
  }
}))(Tooltip)
