import { all, fork, takeLatest } from 'redux-saga/effects'
import { PageUrls } from 'utils/constants'
import { fetchHandler } from 'store/common/sagas'
import { SubscriptionActionTypes } from './types'
import { fetchError, fetchSuccess } from './actions'

function* watchFetchRequest() {
  yield takeLatest(
    SubscriptionActionTypes.FETCH_REQUEST,
    fetchHandler({
      onError: [fetchError],
      onSuccess: [fetchSuccess],
      apiEndpoint: PageUrls.SubscriptionApi
    })
  )
}

function* subscriptionSaga() {
  yield all([fork(watchFetchRequest)])
}

export default subscriptionSaga
