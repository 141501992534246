import { Container, createStyles, Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { StyledH2NoMargin } from 'components/common';
import Page from 'components/common/layout/Page';
import { CentralTariffHelperBlock, LeftTariffHelperBlock, RightTariffHelperBlock } from 'components/subscription';
import { translations } from 'i18n/rus';
import React from 'react';
import { Disclaimer } from './teacher/style';

const useStyles = makeStyles(() =>
    createStyles({
        subheader: {
            marginTop: 15,
            textAlign: 'center',
            fontWeight: 200,
            maxWidth: 450,
        },
        disclaimer: {
            marginTop: 30,
            maxWidth: 600,
        },
    }),
);

const SubscriptionPage: React.FC = () => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return (
        <Page>
            <Container>
                <Grid alignItems="center" direction="column" container={true}>
                    <StyledH2NoMargin>{translations.subscriptionHeader}</StyledH2NoMargin>
                    <h4 className={classes.subheader}>{translations.subscriptionSubheader}</h4>
                    <Grid container={true}>
                        <Grid container={true} direction={mobile ? 'column' : 'row'} justify="center" alignItems="center" wrap="nowrap">
                            <LeftTariffHelperBlock />
                            <CentralTariffHelperBlock />
                            <RightTariffHelperBlock />
                        </Grid>
                    </Grid>
                    <Disclaimer className={classes.disclaimer}>{translations.subscriptionDisclaimer}</Disclaimer>
                </Grid>
            </Container>
        </Page>
    );
};
export default SubscriptionPage;
