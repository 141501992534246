import { withStyles } from '@material-ui/styles'

import { Theme, createStyles, ListItemIcon, styled, Link, lighten, LinearProgress } from '@material-ui/core'

export const StyledListItemIcon = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 36
    }
  })
)(ListItemIcon)

export const TextLink = styled(Link)(({ theme }) => ({
  color: theme.colors.brand
}))

enum ProgressLevel {
  None,
  Low,
  Normal,
  High
}

const getProgressColor = (level: ProgressLevel, theme: Theme) => {
  switch (level) {
    case ProgressLevel.None:
      return '#808080'
    case ProgressLevel.Low:
      return theme.colors.lowRate
    case ProgressLevel.Normal:
      return theme.colors.normalRate
    case ProgressLevel.High:
      return theme.colors.highRate
  }
}

export const getRatingLinearProgress = (level: ProgressLevel) =>
  withStyles(theme => ({
    root: {
      height: 30,
      width: '200px',
      borderRadius: 7,
      backgroundColor: lighten(getProgressColor(level, theme), 0.5),
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        width: '400px'
      }
    },
    bar: {
      backgroundColor: getProgressColor(level, theme)
    }
  }))(LinearProgress)

export const getProgressLevel = (progress: number) => {
  if (progress === 0) return ProgressLevel.None
  if (progress > 0 && progress < 0.4) return ProgressLevel.Low
  if (progress >= 0.4 && progress < 0.8) return ProgressLevel.Normal
  return ProgressLevel.High
}
