import { styled, Avatar, Divider, Grid } from '@material-ui/core'
import Button, { ButtonProps } from '@material-ui/core/Button'
import React from 'react'
import { Link } from 'react-router-dom'
import { GridProps } from '@material-ui/core/Grid'

// eslint-disable-next-line react/jsx-props-no-spreading
const OutlinedButton = ({ ...props }: ButtonProps) => <Button variant="outlined" {...props} />

export const StyledOutlinedButton = styled(OutlinedButton)(({ theme }) => ({
  borderColor: theme.colors.brand,
  color: theme.colors.brand,
  '&:hover': {
    background: theme.colors.brandTransparent
  },
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 300,
  textTransform: 'none',
  paddingLeft: '5px',
  paddingRight: '5px',

  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '12px',
    paddingLeft: '25px',
    paddingRight: '25px'
  }
}))

export const HeaderStyledButton = styled(Button)(({ theme }) => ({
  background: theme.colors.backgroundGradient,
  color: theme.colors.white,
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 300,
  textTransform: 'none',
  marginTop: '5px',
  marginBottom: '5px',
  paddingLeft: '5px',
  paddingRight: '5px',
  '&:hover': {
    background: theme.colors.backgroundGradientLight
  },

  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '35px',
    paddingRight: '35px'
  }
}))

export const BrandStyledButton = styled(Button)(({ theme }) => ({
  background: theme.colors.backgroundGradient,
  color: theme.colors.white,
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 300,
  textTransform: 'none',
  marginTop: '10px',
  marginBottom: '10px',
  paddingLeft: '30px',
  paddingRight: '30px',
  '&:hover': {
    background: theme.colors.backgroundGradientLight
  }
}))

export const WhiteStyledButton = styled(Button)(({ theme }) => ({
  background: theme.colors.white,
  color: theme.colors.black,
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 300,
  textTransform: 'none',
  marginTop: '10px',
  marginBottom: '10px',
  paddingLeft: '30px',
  paddingRight: '30px',
  '&:hover': {
    background: theme.colors.borders
  }
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  background: 'transparent',
  color: theme.colors.black,
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 300,
  textTransform: 'none',

  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '16px'
  }
}))

export const BrandOutlinedButton = styled(OutlinedButton)(({ theme }) => ({
  borderColor: theme.colors.brand,
  color: theme.colors.brand,
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 300,
  textTransform: 'none',
  marginTop: '10px',
  marginBottom: '10px',
  paddingLeft: '20px',
  paddingRight: '20px',
  '&:hover': {
    background: theme.colors.brandTransparent
  }
}))

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: 10,
  width: '40px',
  height: '40px',
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    width: '50px',
    height: '50px'
  }
}))

export const ExternalLink = ({ ...props }: React.HTMLProps<HTMLAnchorElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <a target="_blank" {...props}>
    {props.children}
  </a>
)

// eslint-disable-next-line react/jsx-props-no-spreading
export const ExternalButtonLink = styled(({ ...props }: React.HTMLProps<HTMLAnchorElement>) => <ExternalLink tabIndex={-1} {...props} />)({
  '&:hover': {
    textDecoration: 'none'
  },
  '&:focus': {
    textDecoration: 'none'
  }
})

export const StyledDivider = styled(Divider)(({ theme }) => ({
  background: theme.colors.brand
}))

export const StyledLink = styled(Link)({
  '&:hover, &:focus': {
    textDecoration: 'none'
  }
})

export const TextLink = styled(Link)(({ theme }) => ({
  color: theme.colors.brand
}))

// eslint-disable-next-line react/jsx-props-no-spreading
export const PanelGrid = styled(({ ...props }: GridProps) => <Grid container {...props} />)({
  maxWidth: 900
})

export const StyledH2 = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '1.2em',
  fontWeight: 500,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '1.5em'
  },
  textAlign: 'center'
}))

export const StyledH2NoMargin = styled(StyledH2)(({ theme }) => ({
  margin: 0,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    margin: 0
  }
}))

export const StyledTextLink = styled(Link)(({ theme }) => ({
  color: theme.colors.brand,
  fontWeight: 300
}))
