import { Tariff } from '../common/types'

export enum SubscriptionActionTypes {
  FETCH_REQUEST = '@@subscription/FETCH_REQUEST',
  FETCH_SUCCESS = '@@subscription/FETCH_SUCCESS',
  FETCH_ERROR = '@@subscription/FETCH_ERROR',

  TARIFF_LINK_OPENED = '@@subscription/TARIFF_LINK_OPENED'
}

export interface SubscriptionState {
  readonly loading: boolean
  readonly data: { tariffs: Tariff[] }
  readonly errors?: string
}
