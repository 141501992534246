import { Button, Paper } from '@material-ui/core';
import { createStyles, styled, Theme, withStyles } from '@material-ui/core/styles';

export const LeftBlockPaper = withStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.colors.tariffSecondary,
            borderRadius: 25,
        },
    }),
)(Paper);

export const RightBlockPaper = withStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.colors.tariffPrimary,
            borderRadius: 25,
        },
    }),
)(Paper);

export const CentralBlockPaper = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `3px solid ${theme.colors.tariffPrimary}`,
            borderRadius: 25,
            boxShadow: '0 0 30px 10px grey',
            // boxShadow: '10px 10px 5px grey'
        },
    }),
)(Paper);

export const TariffStyledButton = styled(Button)(({ theme }) => ({
    background: theme.colors.tariffPrimary,
    borderRadius: 15,
    height: 50,
    color: theme.colors.white,
    fontFamily: "'Museo', sans-serif",
    fontSize: 16,
    fontWeight: 300,
    textTransform: 'none',
    marginBottom: '10px',
    paddingLeft: '30px',
    paddingRight: '30px',
    '&:hover': {
        background: theme.colors.tariffPrimaryLight,
    },
    [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        fontSize: 20,
    },
}));
