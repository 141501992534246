import { Theme, createStyles, withStyles, FormControlLabel } from '@material-ui/core'

export const StyledFormControlLabel = withStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 15,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        fontSize: 15
      }
    }
  })
)(FormControlLabel)
