import { action } from 'typesafe-actions'
import { Subject } from 'store/common/types'
import { AccountActionTypes, AccountInfo, RegisterUser } from './types'

export const loadUserData = () => action(AccountActionTypes.LOAD_USER_DATA)

export const loginRequest = (phone: string, password: string) => action(AccountActionTypes.LOGIN_REQUEST, { phone, password })
export const loginSuccess = ({ user: data }: { user: AccountInfo }) => action(AccountActionTypes.LOGIN_SUCCESS, data)
export const loginError = (message: string) => action(AccountActionTypes.LOGIN_ERROR, message)
export const loginTeacherRequest = (phone: string, password: string) =>
  action(AccountActionTypes.LOGIN_TEACHER_REQUEST, { phone, password })
export const loginTeacherSuccess = ({ user: data }: { user: AccountInfo }) => action(AccountActionTypes.LOGIN_TEACHER_SUCCESS, data)
export const loginTeacherError = (message: string) => action(AccountActionTypes.LOGIN_TEACHER_ERROR, message)
export const logout = () => action(AccountActionTypes.LOGOUT)

export const chooseSubjectsRequest = () => action(AccountActionTypes.CHOOSE_SUBJECTS_REQUEST)
export const chooseSubjectsSuccess = (data: { subjects: Subject[] }) => action(AccountActionTypes.CHOOSE_SUBJECTS_SUCCESS, data)
export const chooseSubjectsError = (message: string) => action(AccountActionTypes.CHOOSE_SUBJECTS_ERROR, message)

export const registerRequest = (info: RegisterUser) => action(AccountActionTypes.REGISTER_REQUEST, info)
export const registerSuccess = ({ user: data }: { user: AccountInfo }) => action(AccountActionTypes.REGISTER_SUCCESS, data)
export const registerError = (message: string) => action(AccountActionTypes.REGISTER_ERROR, message)

export const submitSubjectsRequest = (checkedSubjects: { subjects: string[] }) =>
  action(AccountActionTypes.SUBMIT_SUBJECTS_REQUEST, checkedSubjects)
export const submitSubjectsSuccess = () => action(AccountActionTypes.SUBMIT_SUBJECTS_SUCCESS)
export const submitSubjectsError = (message: string) => action(AccountActionTypes.SUBMIT_SUBJECTS_ERROR, message)

export const uploadAvatarRequest = (file: File) => action(AccountActionTypes.UPLOAD_AVATAR_REQUEST, file)
export const uploadAvatarSuccess = (avatar: { avatar: { id: string } }) => action(AccountActionTypes.UPLOAD_AVATAR_SUCCESS, avatar)
export const uploadAvatarError = (message: string) => action(AccountActionTypes.UPLOAD_AVATAR_ERROR, message)
export const resetRegistration = () => action(AccountActionTypes.RESET_REGISTRATION)
