export type ApiResponse = Record<string, any>

export type LessonTarget = {
  lessonTitle: string
  mark?: number
}

export type PracticeTarget = {
  targetPoints: number
}

export type CustomTarget = {
  customTitle: string
}

export type GoalTarget = LessonTarget | PracticeTarget | CustomTarget

export function isLessonTarget(target: GoalTarget): target is LessonTarget {
  return (target as LessonTarget).lessonTitle !== undefined
}

export function isPracticeTarget(target: GoalTarget): target is PracticeTarget {
  return (target as PracticeTarget).targetPoints !== undefined
}

export function isCustomTarget(target: GoalTarget): target is CustomTarget {
  return (target as CustomTarget).customTitle !== undefined
}

export type Goal = {
  title: string
  targets: GoalTarget[]
}

export type MarathonMap = {
  subject: string
  goals: Goal[]
}
