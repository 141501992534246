import { styled, TextField, withStyles, createStyles, Theme , Typography } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'

import React from 'react'

const textFieldStyle = (theme: Theme) =>
  createStyles({
    root: {
      '& label.Mui-focused': {
        color: theme.colors.brand
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.colors.brand
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(12px, 14px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
      },
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px'
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.colors.brand
        },
        '& .MuiInputAdornment-root p': {
          fontSize: '12px',
          userSelect: 'none'
        },
        '&.Mui-focused .MuiInputAdornment-root p': {
          color: theme.colors.brand
        }
      }
    }
  })

export const StyledTextField = withStyles(textFieldStyle)(TextField)
export const StyledDateTimePicker = withStyles(textFieldStyle)(KeyboardDateTimePicker)

export const Disclaimer = styled(props => <Typography color="textSecondary" {...props} />)(({ theme }) => ({
  fontSize: 12,
  textAlign: 'center',
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: 16
  }
}))
