import * as React from 'react'
import { Subject, Teacher, Lesson, BookedLesson } from 'store/common/types'
import { translations } from 'i18n/rus'
import { BookingListPanel } from 'components/common/booking/style'
import { StyledTab, HiddenSpan } from './style'

export interface SubjectTabsProps {
  subjectIds: string[]
  subjectsById: Record<string, Subject>
  teacherById: Record<string, Teacher>
  lessonsBySubjectId: Record<string, Lesson[]>
}

export type IndexSubjectTabsProps = SubjectTabsProps

export interface BookingSubjectTabsProps extends SubjectTabsProps {
  bookedSubjectsById: Record<string, BookedLesson>
  highlightBookings?: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export const mapToTab = (s: Subject, disabled?: boolean) => {
  const toSubjectEmojiPair = () => {
    return (
      <span>
        <HiddenSpan>{s.title} </HiddenSpan>
        <span>{!disabled ? s.emoji : translations.subjectBookedEmoji}</span>
      </span>
    )
  }
  return <StyledTab key={s.id} label={toSubjectEmojiPair()} disabled={disabled} {...a11yProps(s.id)} />
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <BookingListPanel
      role="tabpanel"
      style={{ display: value !== index ? 'none' : 'flex' }}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </BookingListPanel>
  )
}
