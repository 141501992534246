import { styled } from '@material-ui/core'

const LoadingOverlayInner = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
})

export default LoadingOverlayInner
