import { styled } from '@material-ui/core'

const Page = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: '1 1 auto',
  paddingLeft: '1.0rem',
  paddingRight: '1.0rem',
  paddingBottom: '3rem'
}))

export default Page
