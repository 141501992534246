import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Global } from '@emotion/core'

import Root from './components/common/layout/Root'
import Header from './components/common/header'
import SchedulePage from './pages/schedule'
import normalize from './styles/normalize'
import globals from './styles/globals'
import Footer from './components/common/footer'
import TeacherPage from 'pages/teacher'
import { PageUrls } from 'utils/constants'
import SubscriptionPage from 'pages/subscription'
import LoginStudentPage from 'pages/account/loginStudent'
import LoginTeacherPage from 'pages/account/loginTeacher'
import RegistrationPage from 'pages/account/registration'
import ChooseSubjectsPage from 'pages/account/chooseSubjects'
import MarathonIndexPage from 'pages/marathon'

// If your app is big + you have routes with a lot of components, you should consider
// code-splitting your routes! If you bundle stuff up with Webpack, I recommend `react-loadable`.
//
// $ yarn add react-loadable
// $ yarn add --dev @types/react-loadable
//
// The given `pages/` directory provides an example of a directory structure that's easily
// code-splittable.

const Routes: React.SFC = () => {
  return (
    <Root>
      <Global styles={normalize} />
      <Global styles={globals} />
      <Header />
      <Switch>
        <Route exact path="/">
          <Redirect to={PageUrls.LoginNoParams} />
        </Route>
        <Route path={PageUrls.SchedulePrefix} component={SchedulePage} />
        <Route path={PageUrls.TeacherPrefix} component={TeacherPage} />
        <Route path={PageUrls.Subscription} component={SubscriptionPage} />
        <Route path={PageUrls.Login} component={LoginStudentPage} />
        <Route path={PageUrls.LoginTeacher} component={LoginTeacherPage} />
        <Route path={PageUrls.Register} component={RegistrationPage} />
        <Route path={PageUrls.ChooseSubjects} component={ChooseSubjectsPage} />
        <Route path={PageUrls.Marathon} component={MarathonIndexPage} />
        <Route component={() => <div>Not Found</div>} />
      </Switch>
      <Footer />
    </Root>
  )
}

export default Routes
