import { all, fork, takeEvery, put, select } from 'redux-saga/effects'
import { fetchHandler, HttpVerb, plainObjectBodySelector } from 'store/common/sagas'
import { PageUrls } from 'utils/constants'
import { push, LOCATION_CHANGE } from 'connected-react-router'
import { selectLogoutLink, selectAccount } from 'store/account/selectors'
import { callUploadFile } from 'utils/api'
import { tokenExists } from 'utils/helpers'
import {
  loginError,
  loginSuccess,
  loginTeacherError,
  loginTeacherSuccess,
  chooseSubjectsError,
  chooseSubjectsSuccess,
  registerError,
  registerSuccess,
  submitSubjectsError,
  submitSubjectsSuccess,
  uploadAvatarError,
  uploadAvatarSuccess,
  loadUserData,
  logout
} from './actions'
import { AccountActionTypes, isLoggedIn } from './types'

function* watchLoadUserData() {
  yield takeEvery(
    AccountActionTypes.LOAD_USER_DATA,
    fetchHandler({
      onError: [loginError, logout],
      onSuccess: [loginSuccess],
      apiEndpoint: PageUrls.LoadUserDataApi
    })
  )
}

function* watchLoginRequest() {
  yield takeEvery(
    AccountActionTypes.LOGIN_REQUEST,
    fetchHandler({
      onError: [loginError],
      onSuccess: [loginSuccess],
      apiEndpoint: PageUrls.LoginApi,
      httpVerb: HttpVerb.Post,
      authorized: false
    })
  )
}

function* watchLoginTeacherRequest() {
  yield takeEvery(
    AccountActionTypes.LOGIN_TEACHER_REQUEST,
    fetchHandler({
      onError: [loginTeacherError],
      onSuccess: [loginTeacherSuccess],
      apiEndpoint: PageUrls.LoginTeacherApi,
      httpVerb: HttpVerb.Post,
      authorized: false
    })
  )
}

function* redirectLogin() {
  const link = yield select(selectLogoutLink)
  yield put(push(link))
}

function* watchLogout() {
  yield takeEvery(AccountActionTypes.LOGOUT, redirectLogin)
}

function* watchChooseSubjectRequest() {
  yield takeEvery(
    AccountActionTypes.CHOOSE_SUBJECTS_REQUEST,
    fetchHandler({
      onError: [chooseSubjectsError],
      onSuccess: [chooseSubjectsSuccess],
      apiEndpoint: PageUrls.ChooseSubjectsApi
    })
  )
}

function* watchRegisterRequest() {
  yield takeEvery(
    AccountActionTypes.REGISTER_REQUEST,
    fetchHandler({
      onError: [registerError],
      onSuccess: [registerSuccess],
      apiEndpoint: PageUrls.LoginPhoneApi,
      httpVerb: HttpVerb.Post,
      authorized: false
    })
  )
}

function* watchSubmitSubjectsRequest() {
  yield takeEvery(
    AccountActionTypes.SUBMIT_SUBJECTS_REQUEST,
    fetchHandler({
      onError: [submitSubjectsError],
      onSuccess: [submitSubjectsSuccess, () => push(PageUrls.Marathon)],
      apiEndpoint: PageUrls.SubmitSubjectsApi,
      httpVerb: HttpVerb.Patch
    })
  )
}

function* watchUploadAvatarRequest() {
  yield takeEvery(
    AccountActionTypes.UPLOAD_AVATAR_REQUEST,
    fetchHandler({
      onError: [uploadAvatarError],
      onSuccess: [uploadAvatarSuccess],
      apiEndpoint: PageUrls.UploadAvatarApi,
      httpVerb: HttpVerb.Post,
      authorized: false,
      customCallApi: callUploadFile,
      bodySelector: plainObjectBodySelector
    })
  )
}

function* submitLoadUserData() {
  const account = yield select(selectAccount)
  if (tokenExists() && !isLoggedIn(account.data)) yield put(loadUserData())
}

function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, submitLoadUserData)
}

function* accountSaga() {
  yield all([
    fork(watchLoadUserData),
    fork(watchLoginRequest),
    fork(watchLoginTeacherRequest),
    fork(watchLogout),
    fork(watchChooseSubjectRequest),
    fork(watchRegisterRequest),
    fork(watchSubmitSubjectsRequest),
    fork(watchUploadAvatarRequest),
    fork(watchLocationChange)
  ])
}

export default accountSaga
