import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles, Theme, createStyles, styled } from '@material-ui/core/styles'

export const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      borderBottom: '1px solid #e8e8e8',
      width: '100%'
    },
    indicator: {
      backgroundColor: theme.colors.bordersGrad
    },
    flexContainer: {
      justifyContent: 'space-between'
    }
  })
)(Tabs)

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 64,
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:hover': {
        color: theme.colors.brandLight,
        opacity: 1
      },
      '&$selected': {
        color: theme.colors.brand,
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: theme.colors.brandLight
      },
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        minWidth: 72
      }
    },
    selected: {}
  })
)(Tab)

export const HiddenSpan = styled('span')(({ theme }) => ({
  display: 'none',
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    display: 'inline'
  }
}))
