import { Reducer } from 'redux'
import { Subject, Lesson } from 'store/common/types'
import { LOCATION_CHANGE } from 'connected-react-router'
import { PageUrls } from 'utils/constants'
import { TeacherState, TeacherActionTypes, IndexState, PastLessonsState } from './types'

export const indexInitialState: IndexState = {
  data: {
    subjects: [],
    lessons: [],
    teachers: []
  },
  errors: undefined,
  loading: true // first render always loads data
}

export const pastLessonsInitialState: PastLessonsState = {
  data: {
    subjects: [],
    lessons: [],
    teachers: []
  },
  errors: undefined,
  loading: true // first render always loads data
}

export const initialState: TeacherState = {
  index: indexInitialState,
  pastLessons: pastLessonsInitialState,
  subjects: [],
  editLesson: undefined
}

const teacherReducer: Reducer<IndexState> = (state = indexInitialState, action) => {
  switch (action.type) {
    case TeacherActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case TeacherActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case TeacherActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    default: {
      return state
    }
  }
}

const pastLessonsReducer: Reducer<PastLessonsState> = (state = pastLessonsInitialState, action) => {
  switch (action.type) {
    case TeacherActionTypes.FETCH_PAST_REQUEST: {
      return { ...state, loading: true }
    }
    case TeacherActionTypes.FETCH_PAST_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case TeacherActionTypes.FETCH_PAST_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    default: {
      return state
    }
  }
}

const subjectReducer: Reducer<Subject[]> = (state = [], action) => {
  switch (action.type) {
    case TeacherActionTypes.FETCH_SUBJECTS_SUCCESS: {
      return action.payload.subjects
    }

    default: {
      return state
    }
  }
}

const editLessonReducer: Reducer<Lesson | undefined> = (state = undefined, action) => {
  switch (action.type) {
    case TeacherActionTypes.LOAD_EDIT_LESSON_SUCCESS: {
      return action.payload
    }

    case LOCATION_CHANGE: {
      return action.payload.location.pathname.startsWith(PageUrls.TeacherPrefix) ? undefined : state
    }

    default: {
      return state
    }
  }
}

const reducer: Reducer<TeacherState> = (state = initialState, action) => {
  return {
    index: teacherReducer(state.index, action),
    pastLessons: pastLessonsReducer(state.pastLessons, action),
    subjects: subjectReducer(state.subjects, action),
    editLesson: editLessonReducer(state.editLesson, action)
  }
}

export { reducer as teacherReducer }
