const onWeekDayAccusative: { [id: string]: string } = {
  понедельник: 'в понедельник',
  вторник: 'во вторник',
  среда: 'в среду',
  четверг: 'в четверг',
  пятница: 'в пятницу',
  суббота: 'в субботу',
  воскресенье: 'в воскресенье'
}
export const weekDayToAccusative = (weekDay: string): string => onWeekDayAccusative[weekDay]

// 1 место, 2 места
export const placesToGenitive = (places: number): string => {
  if (places === 1) return 'место'
  if (places >= 2 && places < 5) return 'места'
  if (places >= 5 || places === 0) return 'мест'
  throw new Error(`places expected to be positive, but got ${places}`)
}

export const getFirstName = (fullName: string): string => fullName.split(' ').slice(0, 1)[0]
