import React from 'react'

import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { closeDialog, cancelBookingRequest, lessonLinkOpened } from 'store/schedule/actions'
import { useDispatch, useSelector } from 'react-redux'
import { translations } from 'i18n/rus'
import { StyledDivider, StyledOutlinedButton, StyledAvatar, ExternalButtonLink } from 'components/common'
import { Grid } from '@material-ui/core'
import { selectIndexDialogData, selectBookedDialogData, selectDialogState, DialogDataSelectorType } from 'store/schedule/selectors'
import { isDialogOpen } from 'store/schedule/types'
import { splitDateTime, getNameInitials, lessThan10mLeft } from 'utils/helpers'
import { recommendationLink } from 'utils/constants'
import { getAvatarLink } from 'utils/api'
import { CenteredTypography, StyledLink, SmallerDescription } from './style'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    dialogTitle: {
      textAlign: 'center'
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.dialogTitle} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const StyledDialog = withStyles((theme: Theme) => ({
  paper: {
    margin: 0,
    width: '100%',
    [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
      margin: 48,
      width: 'calc(100% - 96px)'
    }
  },
  paperWidthMd: {
    maxWidth: '760px'
  }
}))(Dialog)

interface GenericDialogProps {
  header: string
  selector: DialogDataSelectorType
}

export const GenericDialog: React.SFC<GenericDialogProps> = ({ header, selector }) => {
  const dialogState = useSelector(selectDialogState)
  const dispatch = useDispatch()
  const bookingMappings = useSelector(selector)
  // TODO think what would be optimal
  if (!isDialogOpen(dialogState)) return <></>
  const handleClose = () => dispatch(closeDialog())
  const { bookingId, lessonId } = dialogState
  const lesson = bookingMappings.lessonsById[lessonId]
  const teacher = bookingMappings.teachersById[lesson.teacher]
  const { month, dayOfMonth, weekDay, time } = splitDateTime(lesson.scheduledTime, { weekday: 'long' })
  const avatarLink = getAvatarLink(teacher.avatar)

  return (
    <StyledDialog fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {header}
      </DialogTitle>
      <DialogContent>
        <Grid container alignItems="center" wrap="nowrap" direction="column" style={{ marginBottom: 20 }}>
          <CenteredTypography gutterBottom>
            {translations.dialogLessonInfo(weekDay, dayOfMonth, month, time, lesson.topic)}
          </CenteredTypography>

          <Grid container direction="row" justify="center" alignItems="center" style={{ marginBottom: 20 }}>
            <StyledAvatar src={avatarLink}>{getNameInitials(teacher.name)}</StyledAvatar>
            <SmallerDescription style={{ marginLeft: 10 }}>
              {translations.dialogPlacesLeft(teacher.name, lesson.studentLimit, lesson.placesLeft)}
            </SmallerDescription>
          </Grid>
          {lessThan10mLeft(lesson.scheduledTime) ? (
            <ExternalButtonLink href={lesson.meetingLink}>
              <StyledOutlinedButton onClick={() => dispatch(lessonLinkOpened())}>{translations.dialogJoinLesson}</StyledOutlinedButton>
            </ExternalButtonLink>
          ) : (
            <>
              <SmallerDescription gutterBottom>{translations.dialogLinkWillAppear}</SmallerDescription>
              <StyledOutlinedButton onClick={() => dispatch(cancelBookingRequest(bookingId))}>
                {translations.dialogCancelBooking}
              </StyledOutlinedButton>
            </>
          )}
        </Grid>
        <StyledDivider variant="middle" />
        <Grid container direction="column" style={{ marginTop: 20 }}>
          <Typography
            gutterBottom
            style={{
              alignSelf: 'center',
              fontWeight: 500,
              fontSize: 20
            }}
          >
            {translations.dialogGetReady}
          </Typography>
          <Typography gutterBottom>
            {translations.dialogInstructionLine1Before}
            <StyledLink href={recommendationLink}>{translations.dialogInstructionLine1Link}</StyledLink>
          </Typography>
          <Typography gutterBottom>{translations.dialogInstructionLine2}</Typography>
          <Typography gutterBottom>{translations.dialogInstructionLine3}</Typography>
        </Grid>
      </DialogContent>
    </StyledDialog>
  )
}

export const DetailsDialog = () => {
  return <GenericDialog header={translations.dialogTitle} selector={selectIndexDialogData} />
}

export const BookedDialog = () => {
  return <GenericDialog header={translations.bookedDialogTitle} selector={selectBookedDialogData} />
}
