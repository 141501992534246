import React from 'react'
import { useDispatch } from 'react-redux'
import { translations } from 'i18n/rus'
import { openDialog } from 'store/schedule/actions'
import { Grid } from '@material-ui/core'
import { Lesson } from 'store/common/types'
import { StyledTabs } from './style'
import { DateBookingTimeslot } from '../../common/booking/BookingTimeslot'
import { IndexSubjectTabsProps, mapToTab, TabPanel } from '.'
import { BookingListItem } from '../../common/booking/BookingListItem'

const ScheduleSubjectTabs: React.SFC<IndexSubjectTabsProps> = ({ subjectIds, subjectsById, lessonsBySubjectId, teacherById }) => {
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const subjectTabs = subjectIds.map(s => subjectsById[s]).map(s => mapToTab(s))
  const allSubject = { id: 'all-subject', title: '', emoji: translations.allSubjects }
  const allSubjectsTab = mapToTab(allSubject)
  subjectTabs.unshift(allSubjectsTab)

  const mapToTabPanel = ([subject, lessons]: [number, Lesson[]], index: number) => {
    return (
      <TabPanel key={subject} value={value} index={index}>
        {lessons.map(l => (
          <BookingListItem
            key={l.id}
            lesson={l}
            teacher={teacherById[l.teacher]}
            subject={index === 0 ? subjectsById[l.subject] : undefined}
            dialogOpen={() => dispatch(openDialog(l.id, l.id))} // for schedule booking id is equal to lesson id
            TimeslotComponent={DateBookingTimeslot}
            buttonText={translations.detailsButton}
          />
        ))}
      </TabPanel>
    )
  }

  const subjectTabPanels = [0, ...subjectIds].map(s => [s, lessonsBySubjectId[s]] as [number, Lesson[]]).map(mapToTabPanel)

  return (
    <Grid container>
      <StyledTabs value={value} onChange={handleChange}>
        {subjectTabs}
      </StyledTabs>
      {subjectTabPanels}
    </Grid>
  )
}

export default ScheduleSubjectTabs
