import React from 'react'
import { Grid } from '@material-ui/core'
import { BookingListItem } from 'components/common/booking/BookingListItem'
import { DateBookingTimeslot } from 'components/common/booking/BookingTimeslot'
import { BookingListPanel } from 'components/common/booking/style'
import { SchedulePanelProps } from '.'

const TeacherPastLessonsPanel: React.SFC<SchedulePanelProps> = ({ lessons, subjectsById, teacherById }) => {
  return (
    <Grid container>
      <BookingListPanel>
        {lessons.map(l => (
          <BookingListItem
            key={l.id}
            lesson={l}
            teacher={teacherById[l.teacher]}
            subject={subjectsById[l.subject]}
            TimeslotComponent={DateBookingTimeslot}
          />
        ))}
      </BookingListPanel>
    </Grid>
  )
}

export default TeacherPastLessonsPanel
