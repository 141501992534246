import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'
import { Grid, makeStyles, createStyles } from '@material-ui/core'
import { fetchLessonRequest } from 'store/schedule/actions'
import { selectSubjects } from 'store/marathon/selectors'
import { useState } from 'react'
import { TabPanel, mapToTab } from 'components/schedule/subjectTabs'
import { MarathonStepper } from 'components/marathon'
import { MarathonData } from 'store/marathon/mockData'
import { Subject } from 'store/common/types'
import { PanelGrid } from 'components/common'
import { StyledTabs } from 'components/schedule/subjectTabs/style'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%'
    },
    tabPanel: {
      marginTop: 0,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        marginTop: 20
      }
    }
  })
)

const MarathonIndexPage: React.FC = () => {
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()
  const subjects = useSelector(selectSubjects)

  React.useEffect(() => {
    dispatch(fetchLessonRequest())
  }, [dispatch])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const generateTabPanel = (subject: Subject) => {
    const data = MarathonData.find(m => m.subject === subject.title)
    return data ? <MarathonStepper marathonMap={data} subjectId={subject.id} /> : <></>
  }

  return (
    <Page>
      <Container>
        <Grid className={classes.root}>
          <StyledTabs value={value} onChange={handleChange} aria-label="icon label tabs example">
            {subjects.map(s => mapToTab(s))}
          </StyledTabs>
          {subjects.map((s, i) => (
            <TabPanel key={s.id} value={value} index={i}>
              <PanelGrid className={classes.tabPanel} container justify="center">
                {generateTabPanel(s)}
              </PanelGrid>
            </TabPanel>
          ))}
        </Grid>
      </Container>
    </Page>
  )
}

export default MarathonIndexPage
