import { Reducer } from 'redux'
import { SubscriptionActionTypes, SubscriptionState } from './types'

export const initialState: SubscriptionState = {
  data: {
    tariffs: []
  },
  errors: undefined,
  loading: false
}

const reducer: Reducer<SubscriptionState> = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case SubscriptionActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: { tariffs: action.payload.tariffs } }
    }
    case SubscriptionActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    default: {
      return state
    }
  }
}

export { reducer as subscriptionReducer }
