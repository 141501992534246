import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { ExternalLink } from 'components/common'

export const BasicTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '15px'
  }
}))

export const CenteredTypography = styled(BasicTypography)({
  textAlign: 'center'
})

export const StyledLink = styled(ExternalLink)(({ theme }) => ({
  color: theme.colors.brand,
  fontWeight: 300
}))

export const SmallerDescription = styled(BasicTypography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Roboto',
  textAlign: 'center',
  color: theme.colors.secondaryText
}))
