import { Subject, Teacher, Lesson, BookedLesson, LoadingDataState } from '../common/types'

export type ApiResponse = Record<string, any>

export enum ScheduleActionTypes {
  FETCH_REQUEST = '@@schedule/FETCH_REQUEST',
  FETCH_SUCCESS = '@@schedule/FETCH_SUCCESS',
  FETCH_ERROR = '@@schedule/FETCH_ERROR',

  FETCH_LESSONS_REQUEST = '@@schedule/FETCH_LESSONS_REQUEST',
  FETCH_LESSONS_SUCCESS = '@@schedule/FETCH_LESSONS_SUCCESS',
  FETCH_LESSONS_ERROR = '@@schedule/FETCH_LESSONS_ERROR',

  SELECT_DATE = '@@schedule/SELECT_DATE',
  CHANGE_DATE_RANGE = '@@schedule/CHANGE_DATE_RANGE',

  OPEN_DIALOG = '@@schedule/OPEN_DIALOG',
  CLOSE_DIALOG = '@@schedule/CLOSE_DIALOG',

  CREATE_BOOKING_REQUEST = '@@schedule/CREATE_BOOKING_REQUEST',
  CREATE_BOOKING_SUCCESS = '@@schedule/CREATE_BOOKING_SUCCESS',
  CREATE_BOOKING_ERROR = '@@schedule/CREATE_BOOKING_ERROR',

  CANCEL_BOOKING_REQUEST = '@@schedule/CANCEL_BOOKING_REQUEST',
  CANCEL_BOOKING_SUCCESS = '@@schedule/CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_ERROR = '@@schedule/CANCEL_BOOKING_ERROR',

  LESSON_LINK_OPENED = '@@schedule/LESSON_LINK_OPENED'
}

export interface BookingSuccessResponse {
  booking: {
    id: string
    lesson: {
      id: string
      subject: string
    }
  }
}

export interface AuxiliaryLessonData {
  subjects: Subject[]
  teachers: Teacher[]
}

export interface BookingData extends AuxiliaryLessonData {
  bookings: Lesson[]
}

export interface LessonData extends AuxiliaryLessonData {
  lessons: Lesson[]
  bookedLessons: BookedLesson[]
}

export interface DialogOpen {
  bookingId: string
  lessonId: string
}
export type DialogClosed = {}
export type DialogState = DialogOpen | DialogClosed
export function isDialogOpen(state: DialogState): state is DialogOpen {
  return (state as DialogOpen).bookingId !== undefined && (state as DialogOpen).lessonId !== undefined
}

export interface DateSelectorState {
  selectedDate: number
  displayedDates: Date[]
}

export type IndexState = LoadingDataState<BookingData>

export interface BookingState extends LoadingDataState<LessonData> {
  readonly dateSelectorState: DateSelectorState
}

export interface ScheduleState {
  readonly index: IndexState
  readonly booking: BookingState
  readonly dialogState: DialogState
}
