import { LoadingDataState, Subject } from 'store/common/types'

export interface AccountInfo {
  id: string
  name: string
  avatar: string
  token: string
  subscriptionExpiresAt: Date
}

export interface RegisterUser {
  name?: string
  email?: string
  phone: string
  password?: string
  timezone: string
  avatar?: string
}

export enum AccountActionTypes {
  LOAD_USER_DATA = '@@account/LOAD_USER_DATA',

  LOGIN_REQUEST = '@@account/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@account/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@account/LOGIN_ERROR',
  LOGIN_TEACHER_REQUEST = '@@account/LOGIN_TEACHER_REQUEST',
  LOGIN_TEACHER_SUCCESS = '@@account/LOGIN_TEACHER_SUCCESS',
  LOGIN_TEACHER_ERROR = '@@account/LOGIN_TEACHER_ERROR',
  LOGOUT = '@@account/LOGOUT',
  REGISTER_REQUEST = '@@account/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@@account/REGISTER_SUCCESS',
  REGISTER_ERROR = '@@account/REGISTER_ERROR',
  CHOOSE_SUBJECTS_REQUEST = '@@account/CHOOSE_SUBJECTS_REQUEST',
  CHOOSE_SUBJECTS_SUCCESS = '@@account/CHOOSE_SUBJECTS_SUCCESS',
  CHOOSE_SUBJECTS_ERROR = '@@account/CHOOSE_SUBJECTS_ERROR',
  SUBMIT_SUBJECTS_REQUEST = '@@account/SUBMIT_SUBJECTS_REQUEST',
  SUBMIT_SUBJECTS_SUCCESS = '@@account/SUBMIT_SUBJECTS_SUCCESS',
  SUBMIT_SUBJECTS_ERROR = '@@account/SUBMIT_SUBJECTS_ERROR',

  RESET_REGISTRATION = '@@account/RESET_REGISTRATION',
  UPLOAD_AVATAR_REQUEST = '@@account/UPLOAD_AVATAR_REQUEST',
  UPLOAD_AVATAR_SUCCESS = '@@account/UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_ERROR = '@@account/UPLOAD_AVATAR_ERROR'
}

export interface LoginAction {
  type: typeof AccountActionTypes.LOGIN_REQUEST | typeof AccountActionTypes.LOGIN_TEACHER_REQUEST
}

export interface LoggedIn {
  info: AccountInfo
}
export type LoggedOut = {}

export function isLoggedIn(state: LoggedIn | LoggedOut): state is LoggedIn {
  return (state as LoggedIn).info !== undefined
}

export type LoginState = LoadingDataState<LoggedIn | LoggedOut>
export type ChooseSubjectsState = LoadingDataState<{ subjects: Subject[] }>

export interface RegistrationState {
  avatar?: string
  registrationFailed?: boolean
  isRegistrationFinished?: boolean
}

export interface AccountContext {
  readonly headerText: string
  readonly headerLink: string
  readonly scheduleLink: string
  readonly logoutLink: string
}

export type AccountState = {
  readonly context: AccountContext
  readonly login: LoginState
  readonly subjects: ChooseSubjectsState
  readonly registration: RegistrationState
}
