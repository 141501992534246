import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { PageUrls } from 'utils/constants'
import { fetchHandler, HttpVerb, endpointWithPayload } from 'store/common/sagas'
import { ScheduleActionTypes } from './types'
import {
  fetchError,
  fetchSuccess,
  fetchLessonError,
  fetchLessonSuccess,
  createBookingError,
  createBookingSuccess,
  cancelBookingError,
  cancelBookingSuccess,
  fetchRequest,
  fetchLessonRequest
} from './actions'

function* watchFetchRequest() {
  yield takeLatest(
    ScheduleActionTypes.FETCH_REQUEST,
    fetchHandler({
      onError: [fetchError],
      onSuccess: [fetchSuccess],
      apiEndpoint: PageUrls.ScheduleApiIndex
    })
  )
}

function* watchFetchLessonsRequest() {
  yield takeLatest(
    ScheduleActionTypes.FETCH_LESSONS_REQUEST,
    fetchHandler({
      onError: [fetchLessonError],
      onSuccess: [fetchLessonSuccess],
      apiEndpoint: PageUrls.ScheduleApiBooking
    })
  )
}

function* watchCreateBookingRequest() {
  yield takeEvery(
    ScheduleActionTypes.CREATE_BOOKING_REQUEST,
    fetchHandler({
      onError: [createBookingError],
      onSuccess: [createBookingSuccess],
      apiEndpoint: PageUrls.ScheduleApiCreateBooking,
      httpVerb: HttpVerb.Post
    })
  )
}

function* watchCancelBookingRequest() {
  yield takeEvery(
    ScheduleActionTypes.CANCEL_BOOKING_REQUEST,
    fetchHandler({
      onError: [cancelBookingError],
      // TODO that's not a good way to make it work, it's better to split cancel for schedule and booking pages
      onSuccess: [cancelBookingSuccess, fetchRequest, fetchLessonRequest],
      apiEndpoint: PageUrls.ScheduleApiCreateBooking,
      httpVerb: HttpVerb.Delete,
      apiEndpointSelector: endpointWithPayload
    })
  )
}

function* scheduleSaga() {
  yield all([fork(watchFetchRequest), fork(watchFetchLessonsRequest), fork(watchCreateBookingRequest), fork(watchCancelBookingRequest)])
}

export default scheduleSaga
