import { MarathonMap } from '../types'

export const russian: MarathonMap = {
  subject: 'Русский язык',
  goals: [
    {
      title: '🤔 Научись работать с текстом',
      targets: [
        {
          lessonTitle: 'Задание 1. Информационный анализ текста',
          mark: 1
        },
        {
          lessonTitle: 'Задание 2. Средства связи предложений в тексте',
          mark: 1
        },
        {
          lessonTitle: 'Задание 3. Лексическое значение слова',
          mark: 1
        },
        {
          targetPoints: 3
        }
      ]
    },
    {
      title: '📚 Подготовься к базовым заданиям',
      targets: [
        {
          lessonTitle: 'Задание 4. Орфоэпические нормы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 5. Лексические нормы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 6. Лексические нормы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 7. Морфологические нормы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 8. Синтаксические нормы',
          mark: 5
        },
        {
          targetPoints: 9
        }
      ]
    },
    {
      title: '✍️ Подтяни орфографию',
      targets: [
        {
          lessonTitle: 'Задание 9. Правописание корней',
          mark: 1
        },
        {
          lessonTitle: 'Задание 10. Правописание приставок',
          mark: 1
        },
        {
          lessonTitle: 'Задание 11. Правописание суффиксов (кроме Н и НН)',
          mark: 1
        },
        {
          lessonTitle: 'Задание 12. Правописание личных окончаний',
          mark: 1
        },
        {
          lessonTitle: 'Задание 13. Правописание НЕ и НИ',
          mark: 1
        },
        {
          lessonTitle: 'Задание 14. Слитное, дефисное, раздельное написание слов',
          mark: 1
        },
        {
          lessonTitle: 'Задание 15. Правописание Н и НН в суффиксах',
          mark: 1
        },
        {
          targetPoints: 7
        }
      ]
    },
    {
      title: '❗ Овладей знаками препинания',
      targets: [
        {
          lessonTitle: 'Задание 16. Запятые в простом предложении с однородными членами',
          mark: 2
        },
        {
          lessonTitle: 'Задание 17. Запятые при обособленных членах предложения (определение, дополнение, обстоятельство, приложения)',
          mark: 1
        },
        {
          lessonTitle: 'Задание 18. Знаки препинания при обращении и вводных словах',
          mark: 1
        },
        {
          lessonTitle: 'Задание 19. Знаки препинания в сложноподчинённых предложениях',
          mark: 1
        },
        {
          lessonTitle: 'Задание 20. Знаки препинания в предложениях с разными видами связи',
          mark: 1
        },
        {
          lessonTitle: 'Задание 21. Пунктуационный анализ простых и сложных предложений',
          mark: 1
        },
        {
          targetPoints: 7
        }
      ]
    },
    {
      title: '📝 Приручи анализ текста',
      targets: [
        {
          lessonTitle: 'Задание 22. Смысловая и композиционная целостность текста',
          mark: 1
        },
        {
          lessonTitle: 'Задание 23. Функционально-смысловые типы речи',
          mark: 1
        },
        {
          lessonTitle: 'Задание 24. Лексическое значение слова',
          mark: 1
        },
        {
          lessonTitle: 'Задание 25. Средства связи предложений в тексте',
          mark: 1
        },
        {
          lessonTitle: 'Задание 26. Средства речевой выразительности',
          mark: 4
        },
        {
          targetPoints: 8
        }
      ]
    },
    {
      title: '✒️ Научись получать максимум баллов за сочинение',
      targets: [
        {
          lessonTitle: 'Задание 27. Подготовка к сочинению'
        },
        {
          lessonTitle: 'Задание 27. Разбираем проблемы и аргументы сочинения'
        },
        {
          customTitle: 'Отправь свое сочинение на проверку куратору 📄'
        }
      ]
    }
  ]
}
