import { styled } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { StyledOutlinedButton } from 'components/common'

export const BookingListPanel = styled('div')(() => ({
  marginTop: 10,
  flexDirection: 'column',
  width: '100%',
  // overflow: 'scroll',
  height: 'auto'
}))

export const BookingListItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.colors.borders}`,
  boxSizing: 'border-box',
  borderRadius: '5px',
  height: 'auto',
  marginTop: 20,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    height: '75px'
  }
}))

export const BookingTimeslotContainer = styled('div')(({ theme }) => ({
  minWidth: '70px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: 5,
  marginBottom: 5,
  paddingLeft: 5,
  paddingRight: 5,
  borderRight: 'none',

  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    minWidth: '100px',
    borderRight: `1px solid ${theme.colors.borders}`
  }
}))

export const GridAutoWidth = styled(Grid)({
  width: 'auto'
})

export const DetailsButton = styled(StyledOutlinedButton)(() => ({}))

export const BookingTitle = styled('div')(({ theme }) => ({
  fontSize: '13px',
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  fontFamily: 'Roboto',
  minWidth: 0,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    maxHeight: '20px', // one line
    fontSize: '15px'
  }
}))

export const BookingDescription = styled('div')(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // overflowWrap: 'break-word',
  fontFamily: 'Roboto',
  color: theme.colors.secondaryText,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    maxHeight: '32px' // two lines
  }
}))

export const TimeslotText = styled('div')(({ theme }) => ({
  fontSize: '12px',
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '14px'
  }
}))
