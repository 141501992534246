import { call, put, all } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { PageUrls } from 'utils/constants'
import { loadUserData } from 'store/account/actions'
import { enqueueSnackbar } from 'store/notification/actions'
import { createWarning } from 'store/notification/snackbarActions'
import { useState, useEffect } from 'react'
import { callApi, callAuthorizedApi, PROTOCOL, isProduction } from '../../utils/api'

const apiEndpointSelector = (apiExt: string): string => {
  if (apiExt.startsWith(PageUrls.ScheduleApiSearch)) return process.env.REACT_APP_LESSON_API || 'https://test.api.nexted.ai'

  switch (apiExt) {
    case PageUrls.UploadAvatarApi:
    case PageUrls.RegisterApi:
    case PageUrls.LoginApi:
    case PageUrls.LoginPhoneApi:
    case PageUrls.LoginTeacherApi:
    case PageUrls.ChooseSubjectsApi:
    case PageUrls.SubmitSubjectsApi:
    case PageUrls.TeacherApiSubjects:
    case PageUrls.LoadUserDataApi:
      return process.env.REACT_APP_ONBOARDING_API || 'https://test.api.nexted.ai'
    case PageUrls.ScheduleApiIndex:
    case PageUrls.ScheduleApiBooking:
    case PageUrls.TeacherApiSchedule:
    case PageUrls.TeacherApiPastLessons:
    case PageUrls.TeacherApiCreate:
    case PageUrls.TeacherApiGetLesson:
    case PageUrls.ScheduleApiCreateBooking:
      return process.env.REACT_APP_LESSON_API || 'https://test.api.nexted.ai'
    case PageUrls.SubscriptionApi:
      return process.env.REACT_APP_SUBSCRIPTION_API || 'https://test.api.nexted.ai'
    default:
      // preventive exception
      throw new Error('API url is not specified, check apiEndpointSelector')
    // return process.env.REACT_APP_API_ENDPOINT || 'https://test.api.nexted.ai'
  }
}

// TODO make fetch accept request group
// { Request = ..., Success, Error...}

export enum HttpVerb {
  Get = 'get',
  Post = 'post',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

interface FetchRequiredParams {
  onError: any[]
  onSuccess: any[]
  apiEndpoint: string
}

interface FetchOptionalParams {
  httpVerb: HttpVerb
  authorized: boolean
  apiEndpointSelector: (e: string, a: any) => string
  bodySelector: (a: any) => any
  customCallApi: any | undefined
}

interface FetchParams extends FetchRequiredParams, Partial<FetchOptionalParams> {}

export const defaultEndpoint = (e: string) => `${PROTOCOL}${apiEndpointSelector(e)}/api${e}`
export const endpointWithPayload = (e: string, p: any) => `${defaultEndpoint(e)}/${p}`
export const endpointWithId = (e: string, p: any) => `${defaultEndpoint(e)}/${p.id}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonBodySelector = (p: any) => JSON.stringify(p)
export const emptyBodySelector = (_: any) => undefined
export const plainObjectBodySelector = (p: any) => p

const defaultBodySelector = (verb: HttpVerb) => {
  switch (verb) {
    case HttpVerb.Get:
      return emptyBodySelector
    case HttpVerb.Post:
      return jsonBodySelector
    case HttpVerb.Patch:
      return jsonBodySelector
    case HttpVerb.Delete:
      return emptyBodySelector
    default:
      return emptyBodySelector
  }
}

const defaultFetchParams: FetchOptionalParams = {
  httpVerb: HttpVerb.Get,
  authorized: true,
  apiEndpointSelector: (e, _) => defaultEndpoint(e),
  bodySelector: emptyBodySelector,
  customCallApi: undefined
}

const subscriptionExpiredMessage = 'Subscription expired'

const errorHandlerActions = (errorMessage: string) => {
  switch (errorMessage) {
    case subscriptionExpiredMessage:
      return loadUserData()
    default:
      return isProduction() ? undefined : enqueueSnackbar(createWarning(errorMessage))
  }
}

const dispatchAllActions = (actions: any[], payload: any) => {
  return actions.map(action => put(action(payload)))
}

export const fetchHandler = (requiredParams: FetchParams) => {
  const params = { ...defaultFetchParams, ...requiredParams }
  if (!requiredParams.bodySelector) params.bodySelector = defaultBodySelector(params.httpVerb)
  return function* handleFetch(action: AnyAction) {
    try {
      let res: any
      const body = params.bodySelector(action.payload)
      if (!params.authorized) {
        const callApiMethod = params.customCallApi ? params.customCallApi : callApi
        res = yield call(callApiMethod, params.httpVerb, params.apiEndpointSelector(params.apiEndpoint, action.payload), body)
      } else {
        const { token } = localStorage
        if (!token) {
          throw new Error('Token is required')
        }

        res = yield call(callAuthorizedApi, params.httpVerb, params.apiEndpointSelector(params.apiEndpoint, action.payload), token, body)
      }

      if (res.error) {
        yield all(dispatchAllActions(params.onError, res.error))
        const extraAction = errorHandlerActions(res.error.message)
        if (extraAction) yield put(extraAction)
      } else {
        yield all(dispatchAllActions(params.onSuccess, res))
      }
    } catch (err) {
      if (err instanceof Error && err.stack) {
        yield all(dispatchAllActions(params.onError, err.stack))
      } else {
        yield all(dispatchAllActions(params.onError, 'An unknown error occured.'))
      }
    }
  }
}

interface UseFetchOptions {
  httpVerb: HttpVerb
  authorized: boolean
}

const defaultFetchOptions: UseFetchOptions = {
  httpVerb: HttpVerb.Get,
  authorized: true
}

export const useFetch = <T>(url: string, options?: Partial<UseFetchOptions>, data?: any) => {
  const params = { ...defaultFetchOptions, ...options }
  const fullUrl = `${PROTOCOL}${apiEndpointSelector(url)}/api${url}`

  const [response, setResponse] = useState<T | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      let res: any
      if (!params.authorized) {
        res = await callApi(params.httpVerb, fullUrl, JSON.stringify(data))
      } else {
        const { token } = localStorage
        if (!token) {
          throw new Error('Token is required')
        }
        res = await callAuthorizedApi(params.httpVerb, fullUrl, token, JSON.stringify(data))
      }

      if (res.error) {
        setError(res.error)
      } else {
        setResponse(res)
      }
      setLoading(false)
    }
    fetchData()
  }, [])
  return { response, error, loading }
}
