import * as React from 'react'

import { useEffect, useState } from 'react'
import { translations } from 'i18n/rus'
import Page from 'components/common/layout/Page'
import { Container, Grid, FormControl, makeStyles, createStyles, InputAdornment } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { StyledH2, BrandStyledButton } from 'components/common'
import { StyledTextField } from 'pages/teacher/style'
import InputMask from 'react-input-mask'
import { removeSpacesAndUnderlines } from 'utils/helpers'
import { phoneLength, phonePrefix, PageUrls } from 'utils/constants'
import { registerRequest, resetRegistration } from 'store/account/actions'
import * as moment from 'moment-timezone'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { selectRegistrationFailed, selectIsRegistrationFinished } from 'store/account/selectors'

const useStyles = makeStyles(theme =>
  createStyles({
    fullHeight: {
      height: '80vh' // - header size
    },
    textAdornment: {
      whiteSpace: 'nowrap'
    },
    avatarButton: {
      width: 20,
      height: 20,
      minHeight: 20,
      backgroundColor: theme.colors.brand,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: theme.colors.brandLight
      },
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        width: 40,
        height: 40,
        minHeight: 36
      }
    },
    addIcon: {
      color: theme.colors.white
    },
    avatarText: {
      fontSize: 12,
      marginLeft: 5,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        fontSize: '1rem'
      }
    },
    marginTop: {
      marginTop: 20
    }
  })
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegistrationProps
  extends RouteComponentProps<{
    phoneParam?: string
    // nameParam?: string
  }> {}

const formatPhoneMask = (value: string) => value.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})*/, '$1 $2 $3 $4')

const RegistrationPage: React.FC<RegistrationProps> = props => {
  const classes = useStyles()
  // const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const { phoneParam } = props.match.params
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(resetRegistration())
    dispatch(
      registerRequest({
        phone: phonePrefix + phoneParam,
        timezone: moment.tz.guess()
      })
    )
  }, [dispatch])

  const error = useSelector(selectRegistrationFailed)
  const isRegistrationFinished = useSelector(selectIsRegistrationFinished)

  if (error) return <Redirect to={`${PageUrls.LoginNoParams}/${phoneParam}`} />
  if (isRegistrationFinished !== undefined) return <Redirect to={isRegistrationFinished ? PageUrls.Marathon : PageUrls.ChooseSubjects} />

  // const { phoneParam, nameParam } = props.match.params

  // const inputAvatarRef = useRef<HTMLInputElement>(null)
  // const [phone, setPhone] = useState(phoneParam ? formatPhoneMask(phoneParam) : '')
  // const [email, setEmail] = useState('')
  // const [name, setName] = useState(nameParam || '')
  // const [pass, setPass] = useState('')
  // const [pass2, setPass2] = useState('')

  // const [loginInv, setLoginInv] = useState(false)
  // const [emailInv, setEmailInv] = useState(false)
  // const [nameInv, setNameInv] = useState(false)
  // const [passInv, setPassInv] = useState(false)
  // const [pass2Inv, setPass2Inv] = useState(false)

  // const avatar = useSelector(selectAvatarRegistration)
  // const avatarLink = getAvatarLink(avatar)

  // const handleRegister = () => {
  //   const parsedPhoneNumber = removeSpacesAndUnderlines(phone)
  //   const loginValid = parsedPhoneNumber.length === phoneLength
  // const emailValid = !validateEmail(email)
  // const nameValid = name.length >= 4
  // const passValid = pass.length >= 8
  // const pass2Valid = pass2 === pass

  // setLoginInv(!loginValid)
  // setEmailInv(!emailValid)
  // setNameInv(!nameValid)
  // setPassInv(!passValid)
  // setPass2Inv(!pass2Valid)

  //   const allValid = loginValid /* && emailValid && nameValid && passValid  && pass2Valid */
  //   if (allValid) {
  //     dispatch(
  //       registerRequest({
  //         phone: phonePrefix + parsedPhoneNumber,
  //         // name,
  //         // email,
  //         // password: pass,
  //         timezone: moment.tz.guess()
  //         // avatar
  //       })
  //     )
  //   }
  // }

  // https://programmingwithmosh.com/javascript/react-file-upload-proper-server-side-nodejs-easy/
  // const handleAvatarUpload = (event: any) => {
  //   dispatch(uploadAvatarRequest(event.target.files[0]))
  // }

  // const handleFileUploadClick = () => {
  //   if (inputAvatarRef && inputAvatarRef.current) inputAvatarRef.current.click()
  // }

  return (
    <Page>
      <Container>
        <Grid alignItems="center" justify="center" direction="column" container>
          <Grid>
            {/* <StyledH2>{translations.registerHeader}</StyledH2>
            <FormControl className={classes.marginTop} fullWidth> */}
            {/* <Grid container justify="center" alignItems="center">
                <StyledAvatar src={avatarLink}>{getNameInitials(name)}</StyledAvatar>
                <input ref={inputAvatarRef} onChange={handleAvatarUpload} accept="image/*" type="file" style={{ display: 'none' }} />
                <Input
                  disableUnderline
                  inputComponent={() => (
                    <Fab className={classes.avatarButton} onClick={handleFileUploadClick} size="small">
                      <AddIcon fontSize={mobile ? 'small' : 'large'} className={classes.addIcon} />
                    </Fab>
                  )}
                />
                <Typography className={classes.avatarText} color="textSecondary">
                  {translations.uploadAvatar}
                </Typography>
              </Grid> */}
            {/* <StyledTextField
                required
                error={emailInv}
                helperText={emailInv ? translations.validation.email : ''}
                label={translations.registerEmailPlaceholder}
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                variant="outlined"
              /> */}
            {/* <StyledTextField
                required
                error={nameInv}
                label={translations.registerNamePlaceholder}
                value={name}
                onChange={e => setName(e.target.value)}
                margin="normal"
                variant="outlined"
              /> */}
            {/* <InputMask mask="999 999 99 99" value={phone} onChange={e => setPhone(e.target.value)}>
              {() => (
                <StyledTextField
                  required
                  id="phone-required"
                  // error={loginInv}
                  label={translations.registerPhonePlaceholder}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      // eslint-disable-next-line jsx-a11y/accessible-emoji
                      <InputAdornment className={classes.textAdornment} position="start">
                        🇷🇺 {phonePrefix}
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </InputMask> */}
            {/* <StyledTextField
                required
                error={passInv}
                helperText={translations.validation.pass}
                label={translations.registerPasswordPlaceholder}
                value={pass}
                type="password"
                onChange={e => setPass(e.target.value)}
                margin="normal"
                variant="outlined"
              /> */}
            {/* <StyledTextField
                required
                error={pass2Inv}
                helperText={pass2Inv ? translations.validation.pass2 : ''}
                label={translations.registerPasswordRepeatPlaceholder}
                value={pass2}
                type="password"
                onChange={e => setPass2(e.target.value)}
                margin="normal"
                variant="outlined"
              /> */}
            {/* <Grid container justify="center">
                <BrandStyledButton onClick={handleRegister}>{translations.registerButton}</BrandStyledButton>
              </Grid> */}
            {/* </FormControl> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default RegistrationPage
