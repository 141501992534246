import * as React from 'react'
import { isLoggedIn } from 'store/account/types'
import { useSelector } from 'react-redux'
import { selectAccount, selectLogoutLink } from 'store/account/selectors'

import { Redirect } from 'react-router'
import { tokenExists } from './helpers'

export const LoginRequired: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const account = useSelector(selectAccount)
  const link = useSelector(selectLogoutLink)

  if (!tokenExists()) return <Redirect to={link} />
  if (!isLoggedIn(account.data)) return <></>

  return <>{children}</>
}

export const DisplayOrEmpty: React.FC<{ condition: boolean; children?: React.ReactNode }> = ({ condition, children }) => {
  return condition ? <>{children}</> : <></>
}
