import * as React from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'
import { LoginRequired } from 'utils/viewHelpers'
import TeacherIndexPage from './teacher/index'
import TeacherPastLessonsPage from './teacher/pastLessons'
import TeacherCreateLessonPage from './teacher/create'
import TeacherEditLessonPage from './teacher/edit'

const TeacherPage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <LoginRequired>
      <Switch>
        <Route exact path={`${match.path}/`} component={TeacherIndexPage} />
        <Route exact path={`${match.path}/pastLessons`} component={TeacherPastLessonsPage} />
        <Route exact path={`${match.path}/create`} component={TeacherCreateLessonPage} />
        <Route exact path={`${match.path}/edit/:lessonId`} component={TeacherEditLessonPage} />
      </Switch>
    </LoginRequired>
  )
}
export default TeacherPage
