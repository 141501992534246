import { Reducer } from 'redux'

import { NotificationState, NotificationActionType } from './types'

const defaultState: NotificationState = []

const reducer: Reducer<NotificationState> = (state = defaultState, action) => {
  switch (action.type) {
    case NotificationActionType.ENQUEUE_SNACKBAR:
      return [
        ...state,
        {
          key: action.key,
          ...action.notification
        }
      ]

    case NotificationActionType.CLOSE_SNACKBAR:
      return state.map(notification =>
        action.dismissAll || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }
      )

    case NotificationActionType.REMOVE_SNACKBAR:
      return state.filter(notification => notification.key !== action.key)

    default:
      return state
  }
}
export { reducer as notificationReducer }
