import * as React from 'react'
import { PageUrls } from 'utils/constants'
import { loginTeacherRequest } from 'store/account/actions'
import LoginPage from './login'

const LoginTeacherPage: React.FC = () => {
  return <LoginPage loginAction={loginTeacherRequest} showRegister={false} loggedInRedirect={PageUrls.TeacherSchedule} />
}

export default LoginTeacherPage
