import { MarathonMap } from '../types'

export const law: MarathonMap = {
  subject: 'Обществознание',
  goals: [
    {
      title: '⚖️ Подготовься к базовым заданиям',
      targets: [
        {
          lessonTitle: 'Задание 1. Анализ схем и таблиц',
          mark: 1
        },
        {
          lessonTitle: 'Задание 2. Выбор обобщающего понятия',
          mark: 1
        },
        {
          lessonTitle: 'Задание 3. Соотнесение видовых понятий с родовыми',
          mark: 1
        }
      ]
    },
    {
      title: '🤔 Научись «выбирать верные суждения» и «соотносить признаки»',
      targets: [
        {
          lessonTitle: 'Задание 4. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 5. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 6. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 7. Морфологические нормы',
          mark: 1
        },
        {
          lessonTitle: 'Задание 8. Синтаксические нормы',
          mark: 5
        },
        {
          lessonTitle: 'Задание 4. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 5. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 6. Человек и общество',
          mark: 2
        },
        {
          lessonTitle: 'Задание 7. Экономика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 8. Экономика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 9. Экономика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 10. Экономика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 11. Социальные отношения',
          mark: 2
        },
        {
          lessonTitle: 'Задание 12. Социальные отношения',
          mark: 1
        },
        {
          lessonTitle: 'Задание 13. Политика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 14. Политика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 15. Политика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 16. Право',
          mark: 2
        },
        {
          lessonTitle: 'Задание 17. Право',
          mark: 2
        },
        {
          lessonTitle: 'Задание 18. Право',
          mark: 2
        },
        {
          lessonTitle: 'Задание 19. Право',
          mark: 2
        },
        {
          lessonTitle: 'Задание 20. Определение терминов и понятий, соответствующих контексту',
          mark: 2
        }
      ]
    },
    {
      title: '💯 Подготовься получать максимум за задания с развернутым ответом',
      targets: [
        {
          lessonTitle: 'Задание 21. Анализ источников',
          mark: 2
        },
        {
          lessonTitle: 'Задание 22. Анализ источников',
          mark: 2
        },
        {
          lessonTitle: 'Задание 23. Анализ источников',
          mark: 3
        },
        {
          lessonTitle: 'Задание 24. Анализ источников',
          mark: 3
        },
        {
          lessonTitle: 'Задание 25. Задание на раскрытие смысла понятия',
          mark: 4
        },
        {
          lessonTitle: 'Задание 26. Раскрытие теоретических положений на примерах',
          mark: 3
        },
        {
          lessonTitle: 'Задание 27. Задание-задача',
          mark: 3
        },
        {
          lessonTitle: 'Задание 28. Составление плана доклада по определенной теме',
          mark: 4
        }
      ]
    },
    {
      title: '✍️ Научись писать мини-сочинение',
      targets: [
        {
          lessonTitle: 'Задание 29. Мини-сочинение',
          mark: 6
        }
      ]
    }
  ]
}
