import * as React from 'react'
import { Subject, Teacher, Lesson } from 'store/common/types'
import { Grid, useMediaQuery, Theme, makeStyles, createStyles } from '@material-ui/core'
import { translations } from 'i18n/rus'
import { StyledAvatar, StyledDivider } from 'components/common'
import { getNameInitials } from 'utils/helpers'
import { getAvatarLink } from 'utils/api'
import { StyledTooltip } from 'pages/schedule/style'
import { BookingTimeslotProps } from './BookingTimeslot'
import { BookingListItemContainer, GridAutoWidth, DetailsButton, BookingTitle, BookingDescription } from './style'
import { DisplayOrEmpty } from 'utils/viewHelpers'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles(theme =>
  createStyles({
    outerGrid: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      margin: 10,
      width: '100%',
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        flexDirection: 'row',
        margin: 0,
        width: 'calc(100% - 20px)'
      }
    },
    innerGrid: {
      minWidth: 0,
      marginTop: 10,
      marginBottom: 10,
      flexWrap: 'nowrap',
      flexDirection: 'row',
      alignItems: 'center',
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    buttonGrid: {
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      marginRight: 10,
      marginLeft: 10,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        justifyContent: 'flex-end'
      }
    },
    highlightItem: {
      borderColor: theme.colors.brand,
      borderWidth: 2
    }
  })
)

interface BookingButtonViewProps {
  placesLeft: number
  buttonText?: string
  subscriptionExpired?: boolean
  dialogOpen?: () => void
}

const BookingButtonView: React.FC<BookingButtonViewProps> = ({ subscriptionExpired, buttonText, placesLeft, dialogOpen }) => {
  const classes = useStyles()
  return (
    <GridAutoWidth container className={classes.buttonGrid}>
      {subscriptionExpired ? (
        <StyledTooltip
          disableFocusListener
          title={
            <>
              <p>{translations.subscriptionInactive}</p>
            </>
          }
          arrow
        >
          <span>
            <DetailsButton onClick={dialogOpen} disabled>
              {buttonText}
            </DetailsButton>
          </span>
        </StyledTooltip>
      ) : (
        <DetailsButton onClick={dialogOpen} disabled={placesLeft === 0}>
          {buttonText}
        </DetailsButton>
      )}
    </GridAutoWidth>
  )
}

interface BookingListItemProps {
  lesson: Lesson
  teacher: Teacher
  TimeslotComponent: React.SFC<BookingTimeslotProps>
  subscriptionExpired?: boolean
  subject?: Subject
  dialogOpen?: () => void
  buttonText?: string
  highlight?: boolean
}

export const BookingListItem: React.SFC<BookingListItemProps> = ({
  lesson,
  teacher,
  subscriptionExpired,
  subject,
  dialogOpen,
  TimeslotComponent,
  buttonText,
  highlight
}) => {
  const classes = useStyles()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const avatarLink = getAvatarLink(teacher.avatar)
  const margin = 10
  return (
    <BookingListItemContainer className={highlight ? classes.highlightItem : ''}>
      <Grid container className={classes.outerGrid}>
        <TimeslotComponent date={lesson.scheduledTime} />
        {mobile ? <StyledDivider /> : <></>}
        <Grid container className={classes.innerGrid}>
          <StyledAvatar src={avatarLink}>{getNameInitials(teacher.name)}</StyledAvatar>
          <Grid style={{ marginLeft: margin, minWidth: 0 }}>
            <BookingTitle>{lesson.topic}</BookingTitle>
            <BookingDescription>{lesson.description}</BookingDescription>
            <BookingDescription>
              {translations.bookingDescription(teacher.name, lesson.studentLimit, lesson.placesLeft, subject)}
            </BookingDescription>
          </Grid>
        </Grid>
        <DisplayOrEmpty condition={dialogOpen !== undefined || buttonText !== undefined}>
          <BookingButtonView
            // subscriptionExpired={subscriptionExpired}
            buttonText={buttonText}
            placesLeft={lesson.placesLeft}
            dialogOpen={dialogOpen}
          />
        </DisplayOrEmpty>
      </Grid>
    </BookingListItemContainer>
  )
}
