import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectEditLesson } from 'store/teacher/selectors'
import { RouteComponentProps } from 'react-router'
import { translations } from 'i18n/rus'
import { loadEditLessonRequest, editLessonRequest, cancelLessonRequest } from 'store/teacher/actions'
import { LessonCreateEdit } from 'store/teacher/types'
import TeacherCreateEditLessonPage from './createEdit'

interface TeacherEditLessonPageProps {
  lessonId: string
}

const TeacherEditLessonPage: React.FC<RouteComponentProps<TeacherEditLessonPageProps>> = ({ match }) => {
  const dispatch = useDispatch()
  const { lessonId } = match.params
  React.useEffect(() => {
    dispatch(loadEditLessonRequest(lessonId))
  }, [dispatch, lessonId])
  const lesson = useSelector(selectEditLesson)
  if (!lesson) return <></>
  return (
    <TeacherCreateEditLessonPage
      lesson={lesson}
      header={translations.editLessonHeader}
      saveEvent={(lce: LessonCreateEdit) => dispatch(editLessonRequest(lce))}
      cancelEvent={(lid: string) => dispatch(cancelLessonRequest(lid))}
    />
  )
}

export default TeacherEditLessonPage
