import { createSelector } from 'reselect'
import { lessonSorter, lessonsSorterReversed } from 'utils/helpers'
import { ApplicationState } from '..'
import { LessonData } from './types'
import { Subject, Teacher, Lesson } from '../common/types'

export interface IndexDataMappings {
  lessons: Lesson[]
  subjectsById: Record<string, Subject>
  teachersById: Record<string, Teacher>
}

const arrayToDictionary = <T>(reducer: (s: T) => any, array: T[]): Record<number | string, T> =>
  array.reduce((obj: Record<number | string, T>, item: T) => {
    obj[reducer(item)] = item
    return obj
  }, {})

const lessonDataToIdMappings = (lessonData: LessonData, sorter: (a: Lesson, b: Lesson) => number): IndexDataMappings => {
  const sortedLessons = lessonData.lessons.sort(sorter)

  return {
    lessons: sortedLessons,
    subjectsById: arrayToDictionary(s => s.id, lessonData.subjects),
    teachersById: arrayToDictionary(t => t.id, lessonData.teachers)
  }
}

export const selectIndexState = createSelector(
  (state: ApplicationState) => state.teacher,
  teacher => teacher.index
)

export const selectTeacherIndexPageState = createSelector(
  selectIndexState,
  index => ({ ...index, data: lessonDataToIdMappings(index.data, lessonSorter) })
)

export const selectTeacherLessonsState = createSelector(
  selectTeacherIndexPageState,
  index => arrayToDictionary(l => l.id, index.data.lessons)
)

export const selectPastLessonsState = createSelector(
  (state: ApplicationState) => state.teacher,
  teacher => teacher.pastLessons
)

export const selectTeacherPastLessonsPageState = createSelector(
  selectPastLessonsState,
  pastLessons => ({ ...pastLessons, data: lessonDataToIdMappings(pastLessons.data, lessonsSorterReversed) })
)

export const selectSubjectsState = createSelector(
  (state: ApplicationState) => state.teacher,
  teacher => teacher.subjects
)

export const selectEditLesson = createSelector(
  (state: ApplicationState) => state.teacher,
  teacher => teacher.editLesson
)
