import { styled, AppBar, Menu, Avatar, Typography, Toolbar } from '@material-ui/core'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.colors.white
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: 10,
  paddingRight: 10,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    paddingLeft: 16,
    paddingRight: 16
  }
}))

export const StyledMenu = styled(Menu)({
  paper: {
    border: '1px solid #d3d4d5'
  }
})

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: 5,
  marginLeft: 50, // adjust so the middle button in navbar is centered
  width: '30px',
  height: '30px',
  fontSize: '1.00rem',
  '&:hover': {
    cursor: 'pointer'
  },
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    margin: 10,
    marginLeft: 100, // adjust so the middle button in navbar is centered
    width: '40px',
    height: '40px',
    fontSize: '1.25rem'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontWeight: 500,
  fontSize: '20px',
  fontFamily: 'museo-sans, sans-serif',
  fontStyle: 'normal',
  justifyContent: 'center',
  color: theme.colors.black,
  '&:hover': {
    cursor: 'pointer'
  },
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    fontSize: '28px'
  }
}))
