import { transparentize } from 'polished'
import { styled } from '@material-ui/core/styles'

const LoadingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 10,
  background: transparentize(0.25, theme.colors.background)
}))

export default LoadingOverlay
