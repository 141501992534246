import * as React from 'react'
import { PageUrls } from 'utils/constants'
import { loginRequest } from 'store/account/actions'
import LoginPage from './login'
import { RouteComponentProps } from 'react-router'

interface LoginStudengPageProps extends RouteComponentProps<{ phoneParam?: string }> {}

const LoginStudentPage: React.FC<LoginStudengPageProps> = ({
  match: {
    params: { phoneParam }
  }
}) => {
  return <LoginPage loginAction={loginRequest} showRegister loggedInRedirect={PageUrls.Marathon} phoneParam={phoneParam} />
}

export default LoginStudentPage
