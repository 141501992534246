import { action } from 'typesafe-actions'
import { Subject, Lesson } from 'store/common/types'
import { TeacherActionTypes, LessonData, LessonCreateEdit } from './types'

export const fetchRequest = () => action(TeacherActionTypes.FETCH_REQUEST)
export const fetchSuccess = (data: LessonData) => action(TeacherActionTypes.FETCH_SUCCESS, data)
export const fetchError = (message: string) => action(TeacherActionTypes.FETCH_ERROR, message)

export const fetchPastRequest = () => action(TeacherActionTypes.FETCH_PAST_REQUEST)
export const fetchPastSuccess = (data: LessonData) => action(TeacherActionTypes.FETCH_PAST_SUCCESS, data)
export const fetchPastError = (message: string) => action(TeacherActionTypes.FETCH_PAST_ERROR, message)

export const fetchSubjectsRequest = () => action(TeacherActionTypes.FETCH_SUBJECTS_REQUEST)
export const fetchSubjectsSuccess = (data: { subjects: Subject[] }) => action(TeacherActionTypes.FETCH_SUBJECTS_SUCCESS, data)
export const fetchSubjectsError = (message: string) => action(TeacherActionTypes.FETCH_SUBJECTS_ERROR, message)

export const createLessonRequest = (lesson: LessonCreateEdit) => action(TeacherActionTypes.CREATE_LESSON_REQUEST, lesson)
export const createLessonSuccess = () => action(TeacherActionTypes.CREATE_LESSON_SUCCESS)
export const createLessonError = (message: string) => action(TeacherActionTypes.CREATE_LESSON_ERROR, message)

export const loadEditLessonRequest = (lessonId: string) => action(TeacherActionTypes.LOAD_EDIT_LESSON_REQUEST, lessonId)
export const loadEditLessonSuccess = (data: { lesson: Lesson }) => action(TeacherActionTypes.LOAD_EDIT_LESSON_SUCCESS, data.lesson)
export const loadEditLessonError = (message: string) => action(TeacherActionTypes.LOAD_EDIT_LESSON_ERROR, message)

export const cancelLessonRequest = (lessonId: string) => action(TeacherActionTypes.CANCEL_LESSON_REQUEST, lessonId)
export const cancelLessonSuccess = () => action(TeacherActionTypes.CANCEL_LESSON_SUCCESS)
export const cancelLessonError = (message: string) => action(TeacherActionTypes.CANCEL_LESSON_ERROR, message)

export const editLessonRequest = (lesson: LessonCreateEdit) => action(TeacherActionTypes.EDIT_LESSON_REQUEST, lesson)
export const editLessonSuccess = () => action(TeacherActionTypes.EDIT_LESSON_SUCCESS)
export const editLessonError = (message: string) => action(TeacherActionTypes.EDIT_LESSON_ERROR, message)
