import React from 'react'
import { useDispatch } from 'react-redux'
import { translations } from 'i18n/rus'
import { Grid } from '@material-ui/core'
import { BookingListItem } from 'components/common/booking/BookingListItem'
import { DateBookingTimeslot } from 'components/common/booking/BookingTimeslot'
import { BookingListPanel } from 'components/common/booking/style'
import { push } from 'connected-react-router'
import { PageUrls } from 'utils/constants'
import { SchedulePanelProps } from '.'

const TeacherSchedulePanel: React.SFC<SchedulePanelProps> = ({ lessons, subjectsById, teacherById }) => {
  const dispatch = useDispatch()

  return (
    <Grid container>
      <BookingListPanel>
        {lessons.map(l => (
          <BookingListItem
            key={l.id}
            lesson={l}
            teacher={teacherById[l.teacher]}
            subject={subjectsById[l.subject]}
            dialogOpen={() => dispatch(push(`${PageUrls.TeacherEdit}/${l.id}`))}
            TimeslotComponent={DateBookingTimeslot}
            buttonText={translations.editLessonButton}
          />
        ))}
      </BookingListPanel>
    </Grid>
  )
}

export default TeacherSchedulePanel
