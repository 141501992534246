import { Subject, Teacher, Lesson } from '../common/types'

export enum TeacherActionTypes {
  FETCH_REQUEST = '@@teacher/FETCH_REQUEST',
  FETCH_SUCCESS = '@@teacher/FETCH_SUCCESS',
  FETCH_ERROR = '@@teacher/FETCH_ERROR',

  FETCH_PAST_REQUEST = '@@teacher/FETCH_PAST_REQUEST',
  FETCH_PAST_SUCCESS = '@@teacher/FETCH_PAST_SUCCESS',
  FETCH_PAST_ERROR = '@@teacher/FETCH_PAST_ERROR',

  FETCH_SUBJECTS_REQUEST = '@@teacher/FETCH_SUBJECTS_REQUEST',
  FETCH_SUBJECTS_SUCCESS = '@@teacher/FETCH_SUBJECTS_SUCCESS',
  FETCH_SUBJECTS_ERROR = '@@teacher/FETCH_SUBJECTS_ERROR',

  CREATE_LESSON_REQUEST = '@@teacher/CREATE_LESSON_REQUEST',
  CREATE_LESSON_SUCCESS = '@@teacher/CREATE_LESSON_SUCCESS',
  CREATE_LESSON_ERROR = '@@teacher/CREATE_LESSON_ERROR',

  LOAD_EDIT_LESSON_REQUEST = '@@teacher/LOAD_EDIT_LESSON_REQUEST',
  LOAD_EDIT_LESSON_SUCCESS = '@@teacher/LOAD_EDIT_LESSON_SUCCESS',
  LOAD_EDIT_LESSON_ERROR = '@@teacher/LOAD_EDIT_LESSON_ERROR',

  CANCEL_LESSON_REQUEST = '@@teacher/CANCEL_LESSON_REQUEST',
  CANCEL_LESSON_SUCCESS = '@@teacher/CANCEL_LESSON_SUCCESS',
  CANCEL_LESSON_ERROR = '@@teacher/CANCEL_LESSON_ERROR',

  EDIT_LESSON_REQUEST = '@@teacher/EDIT_LESSON_REQUEST',
  EDIT_LESSON_SUCCESS = '@@teacher/EDIT_LESSON_SUCCESS',
  EDIT_LESSON_ERROR = '@@teacher/EDIT_LESSON_ERROR'
}

export interface AuxiliaryLessonData {
  subjects: Subject[]
  teachers: Teacher[]
  lessons: Lesson[]
}

export interface LessonCreateEdit {
  id?: string
  topic: string
  description: string
  meetingLink: string
  subject: string
  scheduledTime: Date
  studentLimit: number
}

export type LessonData = AuxiliaryLessonData

export interface LoadingDataState<T> {
  readonly loading: boolean
  readonly data: T
  readonly errors?: string
}

export type IndexState = LoadingDataState<LessonData>

export type PastLessonsState = LoadingDataState<LessonData>

export interface TeacherState {
  readonly index: IndexState
  readonly pastLessons: PastLessonsState
  readonly subjects: Subject[]
  readonly editLesson: Lesson | undefined
}
