import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Lesson } from 'store/common/types'
import { createBookingRequest } from 'store/schedule/actions'
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core'
import { translations } from 'i18n/rus'
import { selectSubscriptionIsActive } from 'store/account/selectors'
import { StyledTabs } from './style'
import { TimeOnlyBookingTimeslot } from '../../common/booking/BookingTimeslot'
import { DateSelector } from '../datescroll'
import { BookingSubjectTabsProps, mapToTab, TabPanel } from '.'
import { BookingListItem } from '../../common/booking/BookingListItem'
import { DisplayOrEmpty } from 'utils/viewHelpers'
import { push } from 'connected-react-router'
import { PageUrls } from 'utils/constants'

const useStyles = makeStyles(theme =>
  createStyles({
    noData: {
      marginTop: 25,
      justifyContent: 'center',
      textAlign: 'center'
    }
  })
)

const BookingSubjectTabs: React.SFC<BookingSubjectTabsProps> = ({
  subjectIds,
  subjectsById,
  bookedSubjectsById,
  lessonsBySubjectId,
  teacherById,
  highlightBookings
}) => {
  const classes = useStyles()
  const firstEnabledTabPosition = subjectIds.findIndex(id => bookedSubjectsById[id] === undefined)

  const [value, setValue] = React.useState(-1)
  const dispatch = useDispatch()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const currentTabDisabled = bookedSubjectsById[subjectIds[value]] !== undefined
  const subscriptionActive = useSelector(selectSubscriptionIsActive)
  useEffect(() => {
    setValue(firstEnabledTabPosition)
  }, [currentTabDisabled, firstEnabledTabPosition])

  const subjectTabs = subjectIds.map(s => subjectsById[s]).map(s => mapToTab(s, bookedSubjectsById[s.id] !== undefined))

  const mapToTabPanel = ([subject, lessons]: [string, Lesson[]], index: number) => {
    return (
      <TabPanel key={subject} value={value} index={index}>
        <DisplayOrEmpty condition={lessons.length === 0}>
          <Grid container className={classes.noData}>
            <Typography>{translations.noDataPlaceholderText}</Typography>
          </Grid>
        </DisplayOrEmpty>
        {lessons.map(l => (
          <BookingListItem
            highlight={l.topic === highlightBookings}
            key={l.id}
            lesson={l}
            subscriptionExpired={!subscriptionActive}
            teacher={teacherById[l.teacher]}
            dialogOpen={() => dispatch(subscriptionActive ? createBookingRequest(l.id) : push(PageUrls.Subscription))}
            TimeslotComponent={TimeOnlyBookingTimeslot}
            buttonText={translations.bookLessonButton}
          />
        ))}
      </TabPanel>
    )
  }

  const subjectTabPanels = subjectIds.map(s => [s, lessonsBySubjectId[s]] as [string, Lesson[]]).map(mapToTabPanel)

  return (
    <Grid container>
      <StyledTabs value={value} onChange={handleChange}>
        {subjectTabs}
      </StyledTabs>
      <DateSelector />
      {subjectTabPanels}
    </Grid>
  )
}

export default BookingSubjectTabs
