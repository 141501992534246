import { lighten } from '@material-ui/core/styles';

const brandColors = {
    black: '#000000',
    gray75: '#2e2e2c',
    gray60: '#666666',
    gray50: '#7f7f7f',
    gray40: '#999999',
    gray30: '#b2b2b2',
    gray20: '#cccccc',
    gray10: '#e5e5e5',
    gray5: '#ebebea',
    white: '#fefefe',
    yellow: '#f3df49',
    red: '#eb5558',
    green: '#9ee79a',
    orange: '#f59217',
    brand: 'rgba(245,146,23,1)',
    brandLight: lighten('rgba(245,146,23,1)', 0.1),
    brandTransparent: 'rgba(245,146,23,0.08)',
    brandGrad: 'linear-gradient(180deg, rgba(255,88,127,1) 0%, rgba(245,146,23,1) 100%)',
    brandGradLight: `linear-gradient(180deg, ${lighten('rgba(255,88,127,1)', 0.1)} 0%, ${lighten('rgba(245,146,23,1)', 0.1)} 100%)`,
    brandGrad90: 'linear-gradient(90deg, rgba(255,88,127,1) 0%, rgba(245,146,23,1) 100%)',
    tariffPrimary: '#F08E33',
    tariffPrimaryLight: lighten('#F08E33', 0.05),
    tariffSecondary: '#E94151',
};

export default brandColors;
