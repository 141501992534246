import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core'
import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'
import BookingSubjectTabs from 'components/schedule/subjectTabs/booking'
import { BookedDialog } from 'components/schedule/dialog/DetailsDialog'
import { PanelGrid, StyledTextLink } from 'components/common'

import { PageUrls } from 'utils/constants'

import { selectScheduleBookingPageState, selectDialogState } from 'store/schedule/selectors'
import { fetchLessonRequest, searchLesson, createBookingRequest } from 'store/schedule/actions'
import { Redirect, RouteComponentProps } from 'react-router'
import { isDialogOpen } from 'store/schedule/types'
import { LoadingSuspense } from 'components/common/data/LoadingSuspense'
import { useFetch } from 'store/common/sagas'
import { DisplayOrEmpty } from 'utils/viewHelpers'
import { translations } from 'i18n/rus'
import { BookingListItem } from 'components/common/booking/BookingListItem'
import { push } from 'connected-react-router'
import { TimeOnlyBookingTimeslot, DateBookingTimeslot } from 'components/common/booking/BookingTimeslot'
import { selectSubscriptionIsActive } from 'store/account/selectors'
import { Lesson, Teacher } from 'store/common/types'
import { arrayToDictionary } from 'utils/helpers'
import { TabPanel } from 'components/schedule/subjectTabs'

const useStyles = makeStyles(theme =>
  createStyles({
    noData: {
      marginTop: 25,
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'stretch'
    },
    returnLink: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 25
    }
  })
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SearchBookingProps
  extends RouteComponentProps<{
    topic: string
    subject: string
  }> {}

const SearchNoData: React.FC<{ topic: string }> = ({ topic }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.noData}>
      <Typography>
        {translations.noDataSearchTextPart1}
        <b>{translations.noDataSearchTextPart2(topic)}</b>
        {translations.noDataSearchTextPart3}
        <StyledTextLink to={PageUrls.ScheduleBooking}>
          <b>{translations.noDataSearchTextPart4}</b>
        </StyledTextLink>
        {translations.noDataSearchTextPart5}
        <StyledTextLink to={PageUrls.Marathon}>
          <b>{translations.noDataSearchTextPart6}</b>
        </StyledTextLink>
      </Typography>
    </Grid>
  )
}

type SearchResponse = {
  lessons: Lesson[]
  teachers: Teacher[]
}

// TODO if no data = Вы можете марафон или запись
const SearchBookingPage: React.FC<SearchBookingProps> = props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // eslint-disable-next-line react/destructuring-assignment
  const { topic, subject } = props.match.params
  // const dialogState = useSelector(selectDialogState)
  const subscriptionActive = useSelector(selectSubscriptionIsActive)
  const { response, error, loading } = useFetch<SearchResponse>(searchLesson(topic, subject))

  // eslint-disable-next-line prefer-destructuring
  const lessons: Lesson[] = response ? response.lessons : []
  const teacherById: Record<string, Teacher> = response ? arrayToDictionary(t => t.id, response.teachers) : {}
  // const redirect =
  //   !error &&
  //   data.subjectIds.length !== 0 && data.subjectIds.length <= Object.keys(data.bookedSubjectsById).length && !isDialogOpen(dialogState)

  return (
    <Page>
      <Container>
        <Grid alignItems="center" direction="column" container>
          <LoadingSuspense loading={loading}>
            {/* {redirect ? (
              <Redirect to={PageUrls.ScheduleBooking} />
            ) : ( */}
            <PanelGrid>
              <TabPanel key={subject} value={0} index={0}>
                <DisplayOrEmpty condition={lessons.length === 0}>
                  <SearchNoData topic={topic} />
                </DisplayOrEmpty>
                {lessons.map(l => (
                  <BookingListItem
                    key={l.id}
                    lesson={l}
                    subscriptionExpired={!subscriptionActive}
                    teacher={teacherById[l.teacher]}
                    dialogOpen={() => dispatch(subscriptionActive ? createBookingRequest(l.id) : push(PageUrls.Subscription))}
                    TimeslotComponent={DateBookingTimeslot}
                    buttonText={translations.bookLessonButton}
                  />
                ))}
                <DisplayOrEmpty condition={lessons.length !== 0}>
                  <Grid className={classes.returnLink}>
                    <StyledTextLink to={PageUrls.Marathon}>
                      <b>{translations.backToMarathon}</b>
                    </StyledTextLink>
                  </Grid>
                </DisplayOrEmpty>

                <BookedDialog />
              </TabPanel>
            </PanelGrid>

            {/* )} */}
          </LoadingSuspense>
        </Grid>
      </Container>
    </Page>
  )
}

export default SearchBookingPage
