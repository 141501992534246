import { Reducer } from 'redux'
import { range, addDays, getToday, withoutTime } from 'utils/helpers'
import { maxDisplayedDates } from 'utils/constants'
import { ScheduleState, ScheduleActionTypes, IndexState, BookingState, DateSelectorState, DialogState } from './types'

export const indexInitialState: IndexState = {
  data: {
    subjects: [],
    bookings: [],
    teachers: []
  },
  errors: undefined,
  loading: true // first render always loads data
}

export const dateSelectorInitialState: DateSelectorState = {
  selectedDate: 0,
  displayedDates: Array.from(range(maxDisplayedDates)).map(i => withoutTime(addDays(getToday(), i)))
}

export const bookingInitialState: BookingState = {
  data: {
    subjects: [],
    lessons: [],
    teachers: [],
    bookedLessons: []
  },
  errors: undefined,
  loading: true, // first render always loads data
  dateSelectorState: dateSelectorInitialState
}

const dialogInitialState: DialogState = {
  // DialogClosed
}

export const initialState: ScheduleState = {
  index: indexInitialState,
  booking: bookingInitialState,
  dialogState: dialogInitialState
}

const scheduleReducer: Reducer<IndexState> = (state = indexInitialState, action) => {
  switch (action.type) {
    case ScheduleActionTypes.FETCH_REQUEST: {
      return { ...state, loading: true }
    }
    case ScheduleActionTypes.FETCH_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case ScheduleActionTypes.FETCH_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    default: {
      return state
    }
  }
}

const bookingReducer: Reducer<BookingState> = (state = bookingInitialState, action) => {
  switch (action.type) {
    case ScheduleActionTypes.FETCH_LESSONS_REQUEST: {
      return { ...state, loading: true }
    }
    case ScheduleActionTypes.FETCH_LESSONS_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case ScheduleActionTypes.FETCH_LESSONS_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    case ScheduleActionTypes.CANCEL_BOOKING_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          bookedLessons: state.data.bookedLessons.filter(id => id !== action.payload)
        }
      }
    }

    case ScheduleActionTypes.CREATE_BOOKING_SUCCESS: {
      const { bookedLessons } = state.data
      bookedLessons.push({ id: action.payload.booking.lesson.id, subject: action.payload.booking.lesson.subject })
      return { ...state, data: { ...state.data, bookedLessons } }
    }

    case ScheduleActionTypes.SELECT_DATE: {
      return { ...state, dateSelectorState: { ...state.dateSelectorState, selectedDate: action.payload } }
    }

    case ScheduleActionTypes.CHANGE_DATE_RANGE: {
      const baseDate = addDays(state.dateSelectorState.displayedDates[0], action.payload)

      return {
        ...state,
        dateSelectorState: {
          displayedDates: Array.from(range(maxDisplayedDates)).map(i => withoutTime(addDays(baseDate, i))),
          selectedDate: 0
        }
      }
    }

    default: {
      return state
    }
  }
}

const dialogReducer: Reducer<DialogState> = (state = dialogInitialState, action) => {
  switch (action.type) {
    case ScheduleActionTypes.CREATE_BOOKING_SUCCESS: {
      return { ...state, bookingId: action.payload.booking.id, lessonId: action.payload.booking.lesson.id }
    }

    case ScheduleActionTypes.CANCEL_BOOKING_SUCCESS: {
      return {}
    }

    case ScheduleActionTypes.OPEN_DIALOG: {
      return { ...state, bookingId: action.payload.bookingId, lessonId: action.payload.lessonId }
    }

    case ScheduleActionTypes.CLOSE_DIALOG: {
      return {}
    }

    default: {
      return state
    }
  }
}

const reducer: Reducer<ScheduleState> = (state = initialState, action) => {
  return {
    index: scheduleReducer(state.index, action),
    booking: bookingReducer(state.booking, action),
    dialogState: dialogReducer(state.dialogState, action)
  }
}

export { reducer as scheduleReducer }
