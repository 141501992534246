import { styled } from '@material-ui/core'

const Container = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: theme.widths.md,

  [`@media (min-width: ${theme.breakpointsCustom.lg})`]: {
    maxWidth: theme.widths.lg
  },

  [`@media (min-width: ${theme.breakpointsCustom.xl})`]: {
    maxWidth: theme.widths.xl
  }
}))

export default Container
