import { makeStyles, createStyles, Typography, Grid, useMediaQuery, Theme } from '@material-ui/core';
import React from 'react';
import { translations } from 'i18n/rus';
import { ExternalButtonLink } from 'components/common';
import SubscriptionImage from 'static/img/tariffImage.png';
import DoneIcon from '@material-ui/icons/Done';
import { LeftBlockPaper, CentralBlockPaper, TariffStyledButton, RightBlockPaper } from './style';
import { marathonTariffLink } from 'utils/constants';

const useStyles = makeStyles(theme =>
    createStyles({
        card: {
            width: 300,
            height: 280,
        },
        cardCentral: {
            [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
                width: 350,
            },
            width: 300,
            height: 350,
        },
        innerGrid: {
            padding: 15,
            height: '100%',
        },
        priceTitle: {
            marginBottom: 10,
        },
        leftTariffText: {
            color: 'white',
            fontFamily: "'Museo', sans-serif",
            fontWeight: 400,
            marginLeft: 10,
        },
        centralTariffText: {
            color: theme.colors.tariffPrimary,
            fontFamily: "'Museo', sans-serif",
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 18,
        },
        iconStyle: {
            color: 'white',
            fontWeight: 500,
        },
    }),
);

export const LeftTariffHelperBlock: React.FC = () => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    return (
        <Grid item>
            <LeftBlockPaper style={{ marginRight: mobile ? 0 : 10, marginBottom: mobile ? 10 : 0 }} elevation={0} className={classes.card}>
                <Grid className={classes.innerGrid} container direction="column" justify="center">
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffLeftRow1}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffLeftRow2}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffLeftRow3}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText}>{translations.tariffLeftRow4}</Typography>
                    </Grid>
                </Grid>
            </LeftBlockPaper>
        </Grid>
    );
};

export const CentralTariffHelperBlock: React.FC = () => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <Grid item style={{ zIndex: mobile ? 0 : 30 }}>
            <CentralBlockPaper className={classes.cardCentral} elevation={mobile ? 0 : 1}>
                <Grid className={classes.innerGrid} container alignItems="center" justify="center">
                    <Grid container direction="column" alignItems="center" wrap="nowrap">
                        <Typography className={classes.centralTariffText} style={{ fontSize: 24 }}>
                            {translations.tariffCentralHeader}
                        </Typography>
                    </Grid>
                    <img src={SubscriptionImage} />
                    <Grid container direction="column" alignItems="center">
                        <Typography className={classes.centralTariffText} style={{ marginBottom: 10 }}>
                            {translations.tariffCentralSubHeader}
                        </Typography>
                    </Grid>
                    <ExternalButtonLink href={marathonTariffLink} style={{ width: '100%' }}>
                        <TariffStyledButton style={{ width: '100%' }}>{translations.tariffCentralButton}</TariffStyledButton>
                    </ExternalButtonLink>
                </Grid>
            </CentralBlockPaper>
        </Grid>
    );
};

export const RightTariffHelperBlock: React.FC = () => {
    const classes = useStyles();
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <Grid item>
            <RightBlockPaper style={{ marginLeft: mobile ? 0 : 10, marginTop: mobile ? 10 : 0 }} elevation={0} className={classes.card}>
                <Grid className={classes.innerGrid} container direction="column" alignItems="center" justify="center">
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffRightRow1}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffRightRow2}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText} gutterBottom>
                            {translations.tariffRightRow3}
                        </Typography>
                    </Grid>
                    <Grid container direction="row" wrap="nowrap">
                        <DoneIcon className={classes.iconStyle} />
                        <Typography className={classes.leftTariffText}>{translations.tariffRightRow4}</Typography>
                    </Grid>
                </Grid>
            </RightBlockPaper>
        </Grid>
    );
};
