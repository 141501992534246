import { createMuiTheme } from '@material-ui/core';
import { darken } from 'polished';
import brandColors from '../colors/brandColors';

import { lime, red, teal } from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: darken(0.08, brandColors.brand),
        },
    },
    colors: {
        background: brandColors.white,
        backgroundGradient: brandColors.brandGrad,
        backgroundGradientLight: brandColors.brandGradLight,
        body: brandColors.gray75,
        headings: brandColors.black,
        black: brandColors.black,
        white: brandColors.white,
        borders: brandColors.gray20,
        bordersGrad: brandColors.brandGrad90,
        tableOdd: darken(0.025, brandColors.gray5),
        secondaryText: brandColors.gray50,
        tooltip: brandColors.gray75,
        brand: brandColors.brand,
        brandLight: brandColors.brandLight,
        brandTransparent: brandColors.brandTransparent,
        lowRate: red[300],
        normalRate: lime[300],
        highRate: teal[300],
        tariffPrimary: brandColors.tariffPrimary,
        tariffPrimaryLight: brandColors.tariffPrimaryLight,
        tariffSecondary: brandColors.tariffSecondary,
    },
    fonts: {
        headings:
            // eslint-disable-next-line max-len
            "'IBM Plex Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,  Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif",
        body:
            // eslint-disable-next-line max-len
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif',
        monospace: "'IBM Plex Mono', Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace, monospace",
    },
    fontSizes: {
        h1: '2.441rem',
        h2: '1.953rem',
        h3: '1.563rem',
        h4: '1.25rem',
    },
    containerPadding: '1.5rem',
    breakpointsCustom: {
        xs: '0px',
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1920px',
    },
    widths: {
        md: '720px',
        lg: '960px',
        xl: '1140px',
    },
    heights: {
        header: '60px',
    },
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '1.2em',
            },
        },
    },
});

export default theme;
