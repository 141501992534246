import * as React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, makeStyles, createStyles } from '@material-ui/core'
import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'
import BookingSubjectTabs from 'components/schedule/subjectTabs/booking'
import { BookedDialog } from 'components/schedule/dialog/DetailsDialog'
import { StyledLink, PanelGrid, StyledButton } from 'components/common'

import { translations } from 'i18n/rus'
import { PageUrls } from 'utils/constants'

import { selectScheduleBookingPageState, selectDialogState, selectMinimalDate } from 'store/schedule/selectors'
import { fetchLessonRequest, selectDate } from 'store/schedule/actions'
import { Redirect, RouteComponentProps } from 'react-router'
import { isDialogOpen } from 'store/schedule/types'
import { LoadingSuspense } from 'components/common/data/LoadingSuspense'
import { StyledH2 } from './style'

const useStyles = makeStyles(() =>
  createStyles({
    headerText: {
      textAlign: 'center'
    }
  })
)

const HeaderElements: React.FC<{ headerTextClass: string }> = ({ headerTextClass }) => (
  <>
    <StyledLink tabIndex={-1} to={PageUrls.ScheduleIndex}>
      <StyledButton>{translations.lessonMySchedule}</StyledButton>
    </StyledLink>
    <StyledH2 className={headerTextClass}>{translations.lessonBooking}</StyledH2>
  </>
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScheduleBookingProps
  extends RouteComponentProps<{
    name?: string
  }> {}

const ScheduleBookingPage: React.FC<ScheduleBookingProps> = props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { name } = props.match.params
  const dialogState = useSelector(selectDialogState)
  const { loading, data } = useSelector(selectScheduleBookingPageState)

  useEffect(() => {
    dispatch(fetchLessonRequest())
  }, [dispatch])

  const minDate = useSelector(selectMinimalDate)
  useEffect(() => {
    if (minDate !== 0) dispatch(selectDate(minDate))
  }, [dispatch, minDate])

  // Redirect when all subjects are booked and dialog is closed
  const redirect =
    data.subjectIds.length !== 0 && data.subjectIds.length <= Object.keys(data.bookedSubjectsById).length && !isDialogOpen(dialogState)

  return (
    <Page>
      <Container>
        <Grid alignItems="center" direction="column" container>
          <HeaderElements headerTextClass={classes.headerText} />
          <LoadingSuspense loading={loading}>
            {redirect ? (
              <Redirect to={PageUrls.ScheduleIndex} />
            ) : (
              <PanelGrid>
                <BookingSubjectTabs
                  subjectIds={data.subjectIds}
                  subjectsById={data.subjectsById}
                  lessonsBySubjectId={data.lessonsBySubjectId}
                  teacherById={data.teachersById}
                  bookedSubjectsById={data.bookedSubjectsById}
                  highlightBookings={name}
                />
                <BookedDialog />
              </PanelGrid>
            )}
          </LoadingSuspense>
        </Grid>
      </Container>
    </Page>
  )
}

export default ScheduleBookingPage
