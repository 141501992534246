import * as React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'

import { fetchPastRequest } from 'store/teacher/actions'
import { translations } from 'i18n/rus'
import { StyledButton, StyledLink, PanelGrid, StyledH2 } from 'components/common'
import { Grid } from '@material-ui/core'

import { DetailsDialog } from 'components/schedule/dialog/DetailsDialog'
import { selectTeacherPastLessonsPageState } from 'store/teacher/selectors'
import TeacherPastLessonsPanel from 'components/teacher/schedulePanel/pastLessons'
import { PageUrls } from 'utils/constants'
import { Loading } from 'components/common/data/Loading'

const TeacherPastLessonsPage: React.FC = () => {
  const dispatch = useDispatch()
  const { loading, data } = useSelector(selectTeacherPastLessonsPageState)

  useEffect(() => {
    dispatch(fetchPastRequest())
  }, [dispatch])

  return (
    <Page>
      <Container>
        <Grid alignItems="center" direction="column" container>
          <StyledLink tabIndex={-1} to={PageUrls.TeacherSchedule}>
            <StyledButton>{translations.lessonMySchedule}</StyledButton>
          </StyledLink>
          <StyledH2>{translations.pastLessonsHeader}</StyledH2>
          {loading && <Loading />}
          {
            <PanelGrid>
              <TeacherPastLessonsPanel subjectsById={data.subjectsById} lessons={data.lessons} teacherById={data.teachersById} />
              <DetailsDialog />
            </PanelGrid>
          }
        </Grid>
      </Container>
    </Page>
  )
}

export default TeacherPastLessonsPage
