import * as React from 'react'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Page from 'components/common/layout/Page'
import Container from 'components/common/layout/Container'

import { translations } from 'i18n/rus'
import { Grid, FormControl, InputAdornment, Typography, makeStyles, createStyles, MenuItem, Select, InputLabel } from '@material-ui/core'
import { StyledButton, StyledLink, PanelGrid, BrandStyledButton, BrandOutlinedButton, StyledH2NoMargin } from 'components/common'
import { PageUrls, maxTopicLength, maxDescriptionLength } from 'utils/constants'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ruLocale from 'date-fns/locale/ru'
import { getToday, getInMonth, validZoomURL, toUtc } from 'utils/helpers'
import { Lesson } from 'store/common/types'
import { selectSubjectsState } from 'store/teacher/selectors'
import { fetchSubjectsRequest } from 'store/teacher/actions'
import { LessonCreateEdit } from 'store/teacher/types'
import { StyledTextField, StyledDateTimePicker, Disclaimer } from './style'

interface TeacherLessonPageProps {
  header: string
  saveEvent: (lesson: LessonCreateEdit) => void
  lesson?: Lesson
  cancelEvent?: any
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: 25
    }
  })
)

const MaxLengthAdornment: React.FC<{ length: number; maxLength: number }> = ({ length, maxLength }) => {
  return (
    <InputAdornment position="end">
      <Typography color="textSecondary">{`${length}/${maxLength}`}</Typography>
    </InputAdornment>
  )
}

const TeacherCreateEditLessonPage: React.FC<TeacherLessonPageProps> = ({ header, saveEvent, lesson, cancelEvent }) => {
  const classes = useStyles()
  const subjects = useSelector(selectSubjectsState)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchSubjectsRequest())
  }, [dispatch])
  const lessonId = lesson ? lesson.id : undefined

  const [subject, setSubject] = useState(lesson ? lesson.subject : undefined)
  const [subjectInv, setSubjectInv] = useState(false)

  const [topic, setTopic] = useState(lesson ? lesson.topic : '')
  const [topicInv, setTopicInv] = useState(false)

  const [description, setDescription] = useState(lesson ? lesson.description : '')
  const [descriptionInv, setDescriptionInv] = useState(false)

  const [link, setLink] = useState(lesson ? lesson.meetingLink : '')
  const [linkInv, setLinkInv] = useState(false)

  const [selectedDate, setSelectedDate] = useState<Date | null>(lesson ? lesson.scheduledTime : null)
  const [selectedDateInv, setSelectedDateInv] = useState(false)

  const handleSave = () => {
    const subjectValid = subject !== undefined
    const topicValid = topic.trim() !== ''
    const descriptionValid = description.trim() !== ''
    const trimmedLink = link.trim()
    const linkValid = trimmedLink !== '' && validZoomURL(trimmedLink)
    const selectedDateValid = selectedDate !== null

    setSubjectInv(!subjectValid)
    setTopicInv(!topicValid)
    setDescriptionInv(!descriptionValid)
    setLinkInv(!linkValid)
    setSelectedDateInv(!selectedDateValid)

    const allValid = subjectValid && topicValid && descriptionValid && linkValid && selectedDateValid
    // TODO typescript compiler can't properly deduce checks or I'm doing something wrong here
    if (allValid && subject !== undefined && selectedDate !== null)
      saveEvent({
        id: lessonId,
        topic,
        description,
        meetingLink: link,
        subject,
        scheduledTime: toUtc(selectedDate).toDate(),
        studentLimit: 8
      })
  }

  return (
    <Page>
      <Container>
        <Grid alignItems="center" direction="column" container>
          <StyledLink tabIndex={-1} to={PageUrls.TeacherSchedule}>
            <StyledButton>{translations.lessonMySchedule}</StyledButton>
          </StyledLink>
          <StyledH2NoMargin>{translations.graduationCapEmoji}</StyledH2NoMargin>
          <StyledH2NoMargin>{header}</StyledH2NoMargin>
          <PanelGrid>
            <FormControl fullWidth>
              <FormControl>
                <InputLabel id="subject-label">{translations.subjectPlaceholder}</InputLabel>
                <Select
                  required
                  labelId="subject-label"
                  id="subject-required"
                  value={subject}
                  error={subjectInv}
                  onChange={e => setSubject(e.target.value as string)}
                >
                  {subjects.map(s => (
                    <MenuItem value={s.id}>{`${s.emoji} ${s.title}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <StyledTextField
                required
                id="topic-required"
                error={topicInv}
                label={translations.topicPlaceholder}
                value={topic}
                onChange={e => setTopic(e.target.value)}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: maxTopicLength }}
                InputProps={{
                  endAdornment: <MaxLengthAdornment length={topic.length} maxLength={maxTopicLength} />
                }}
              />
              <StyledTextField
                required
                multiline
                rowsMax={10}
                id="descr-required"
                error={descriptionInv}
                label={translations.descriptionPlaceholder}
                value={description}
                onChange={e => setDescription(e.target.value)}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: maxDescriptionLength }}
                InputProps={{
                  endAdornment: <MaxLengthAdornment length={description.length} maxLength={maxDescriptionLength} />
                }}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <Grid container justify="flex-start">
                  <StyledDateTimePicker
                    required
                    error={selectedDateInv}
                    margin="normal"
                    id="date-picker-dialog"
                    label={translations.datePlaceholder}
                    format="MM/dd/yyyy HH:mm"
                    value={selectedDate}
                    initialFocusedDate={getToday()}
                    maxDate={getInMonth()}
                    onChange={date => setSelectedDate(date)}
                    cancelLabel={translations.cancelLabel}
                    okLabel={translations.okLabel}
                    disablePast
                    ampm={false}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <StyledTextField
                required
                error={linkInv}
                label={translations.linkPlaceholder}
                value={link}
                onChange={e => setLink(e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <Grid container justify="center">
                {cancelEvent ? (
                  <BrandOutlinedButton onClick={() => cancelEvent(lessonId)} className={classes.cancelButton}>
                    {translations.editCancelButton}
                  </BrandOutlinedButton>
                ) : (
                  <></>
                )}
                <BrandStyledButton onClick={handleSave}>{translations.saveButton}</BrandStyledButton>
              </Grid>
              {lesson ? <></> : <Disclaimer>{translations.saveClickDisclaimer}</Disclaimer>}
            </FormControl>
          </PanelGrid>
        </Grid>
      </Container>
    </Page>
  )
}

export default TeacherCreateEditLessonPage
