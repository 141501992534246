import React from 'react'
import { Grid, makeStyles, useMediaQuery, Theme } from '@material-ui/core'
import { splitDateTime } from 'utils/helpers'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { maxDisplayedDatesMobile, maxDisplayedDates } from 'utils/constants'
import { useSelector, useDispatch } from 'react-redux'
import { selectDataSelectorState } from 'store/schedule/selectors'
import { selectDate, changeDateRange } from 'store/schedule/actions'
import { TimeslotText } from 'components/common/booking/style'
import { DateSelectionItem, DateSelectionList, DateSelectionControl, DateSelectionItemGrid, ArrowButton } from './style'

const useStyles = makeStyles((theme: Theme) => ({
  widthAuto: {
    width: 'auto'
  },
  height100: {
    height: '100%'
  },
  boldText: {
    fontWeight: 500,
    fontSize: '18px'
  },
  marginLeft: { marginLeft: 5 },
  arrowFab: {
    background: 'none',
    boxShadow: 'none'
  },
  item: {},
  itemSelected: {
    background: theme.colors.backgroundGradient,
    color: theme.colors.white,
    '&:hover': {
      background: theme.colors.backgroundGradientLight
    }
  },
  itemInner: { color: theme.colors.black },
  itemSelectedInner: {
    color: theme.colors.white
  }
}))

export interface DateBookingTimeslotProps {
  date: Date
  selected?: boolean
  onClick?: any
}

export const DateBookingTimeslot: React.SFC<DateBookingTimeslotProps> = ({ selected, date, onClick }) => {
  const classes = useStyles()
  const { month, dayOfMonth, weekDay } = splitDateTime(date)
  return (
    <DateSelectionItem onClick={onClick} className={selected ? classes.itemSelected : classes.item}>
      <DateSelectionItemGrid className={selected ? classes.itemSelectedInner : classes.itemInner}>
        <Grid className={classes.widthAuto} container justify="space-between">
          <TimeslotText className={classes.boldText}>{dayOfMonth}</TimeslotText>
          <TimeslotText className={classes.marginLeft}>{weekDay}</TimeslotText>
        </Grid>
        <Grid className={classes.widthAuto} container justify="flex-start" alignItems="center">
          <TimeslotText>{month}</TimeslotText>
        </Grid>
      </DateSelectionItemGrid>
    </DateSelectionItem>
  )
}

export const DateSelector: React.SFC = () => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const displayDays = mobile ? maxDisplayedDatesMobile : maxDisplayedDates
  const dateSelectorState = useSelector(selectDataSelectorState)
  const selected = dateSelectorState.selectedDate
  const dispatch = useDispatch()
  return (
    <DateSelectionControl>
      <ArrowButton onClick={() => dispatch(changeDateRange(-displayDays))}>
        <KeyboardArrowLeftIcon />
      </ArrowButton>

      <DateSelectionList>
        {dateSelectorState.displayedDates.slice(0, displayDays).map((d, i) => (
          <DateBookingTimeslot onClick={() => dispatch(selectDate(i))} selected={selected === i} date={d} />
        ))}
      </DateSelectionList>

      <ArrowButton onClick={() => dispatch(changeDateRange(displayDays))}>
        <KeyboardArrowRightIcon />
      </ArrowButton>
    </DateSelectionControl>
  )
}
