/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { ApplicationState } from 'store'
import { removeSnackbar } from 'store/notification/actions'

export const Notifier: React.FC = () => {
  const [displayed, setDisplayed] = useState<number[]>([])
  const notifications = useSelector((state: ApplicationState) => state.notifications)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const storeDisplayed = (id: number) => {
    setDisplayed([...displayed, id])
  }

  const removeDisplayed = (id: number) => {
    setDisplayed(displayed.filter(key => id !== key))
  }

  notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
    if (dismissed) {
      closeSnackbar(key)
      return
    }
    // Do nothing if snackbar is already displayed
    if (displayed.includes(key)) return
    // Display snackbar using notistack
    enqueueSnackbar(message, {
      key,
      ...options,
      onClose: (event: any, reason: any, k: number) => {
        if (options.onClose) {
          options.onClose(event, reason, k)
        }
      },
      onExited: (event: any, k: number) => {
        dispatch(removeSnackbar(k))
        removeDisplayed(k)
      }
    })
    // Keep track of snackbars that we've displayed
    storeDisplayed(key)
  })

  return null
}
