// import { requestMock } from '../../tests/mocks/index'
import { PageUrls } from './constants'
import { reviver } from './helpers'

const defaultHeaders = (): Record<string, string> => ({ Accept: 'application/json' })

export async function callApiTest(method: string, url: string, body?: any) {
  const res = await new Promise(resolve => {
    setTimeout(() => {
      // resolve(requestMock(url, body))
    }, 500) // simulate server work/load
  })

  return res
}

// const testApiPrefix = 'test'

export async function callApiBody(method: string, url: string, body: any, headers: Record<string, string>) {
  // if (url.includes(testApiPrefix)) return callApiTest(method, url, body)

  const res = await fetch(url, {
    method,
    headers,
    body
  })
  return res
    .text()
    .then(text => JSON.parse(text, reviver))
    .then(json => {
      if (res.status >= 400 && res.status < 600) {
        return { error: json }
      }
      return json
    })
    .catch(err => {
      if (res.status >= 400 && res.status < 600) {
        return { error: err }
      }
      return {}
    })
}

export async function callApi(method: string, url: string, data?: string) {
  const headers = defaultHeaders()
  headers['Content-Type'] = 'application/json'
  return callApiBody(method, url, data, headers)
}

export async function callAuthorizedApi(method: string, url: string, token: string, data?: string) {
  const headers = defaultHeaders()
  headers['Content-Type'] = 'application/json'
  headers.Authorization = token
  return callApiBody(method, url, data, headers)
}

export async function callUploadFile(method: string, url: string, data?: any) {
  const formData = new FormData()
  formData.append('avatar', data)
  return callApiBody(method, url, formData, defaultHeaders())
}
export const isProduction = () => process.env.NODE_ENV === 'production'
export const PROTOCOL = isProduction() ? 'https://' : 'http://'

export const getAvatarLink = (avatar?: string): string | undefined =>
  avatar ? `${PROTOCOL}${process.env.REACT_APP_STATIC_API}/api${PageUrls.GetAvatarApi}/${avatar}` : undefined
