import React from 'react'
import LoadingOverlay from './LoadingOverlay'
import LoadingOverlayInner from './LoadingOverlayInner'
import { Progress } from './Progress'

export const Loading: React.FC = () => {
  return (
    <LoadingOverlay>
      <LoadingOverlayInner>
        <Progress />
      </LoadingOverlayInner>
    </LoadingOverlay>
  )
}
