import { MarathonMap } from '../types'

export const physics: MarathonMap = {
  subject: 'Физика',
  goals: [
    {
      title: '🚀 Закрепи базовую физику',
      targets: [
        {
          lessonTitle: 'Задание 1. Кинематика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 2. Силы в природе, законы Ньютона',
          mark: 1
        },
        {
          lessonTitle: 'Задание 3. Импульс, энергия, законы сохранения',
          mark: 1
        },
        {
          lessonTitle: 'Задание 4. Механическое равновесие, механические колебания и волны',
          mark: 1
        },
        {
          lessonTitle: 'Задание 5. Механика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 6. Механика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 7. Механика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 8. Тепловое равновесие, уравнение состояния',
          mark: 1
        },
        {
          lessonTitle: 'Задание 9. Термодинамика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 10. Относительная влажность, количество теплоты',
          mark: 1
        },
        {
          lessonTitle: 'Задание 11. МКТ, термодинамика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 12. МКТ, термодинамика',
          mark: 2
        }
      ]
    },
    {
      title: '⚡ Приручи электричество и магнетизм',
      targets: [
        {
          lessonTitle: 'Задание 13. Электрическое поле, магнитное поле',
          mark: 1
        },
        {
          lessonTitle: 'Задание 14. Электричество',
          mark: 1
        },
        {
          lessonTitle: 'Задание 15. Электромагнитная индукция, оптика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 16. Электродинамика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 17. Электродинамика и оптика. Изменение физических величин в процессах',
          mark: 2
        },
        {
          lessonTitle: 'Задание 18. Электродинамика и основы СТО',
          mark: 2
        }
      ]
    },
    {
      title: '⚛️ Пойми ядерную, молекулярную и квантовую физику',
      targets: [
        {
          lessonTitle: 'Задание 19. Ядерная физика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 20. Фотоны, линейчатые спектры, закон радиоактивного распада',
          mark: 1
        },
        {
          lessonTitle: 'Задание 21. Квантовая физика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 22. Механика: квантовая физика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 23. Механика: квантовая физика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 24. Астрофизика',
          mark: 2
        },
        {
          lessonTitle: 'Задание 25. Механика, молекулярная физика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 26. Молекулярная физика, электродинамика',
          mark: 1
        }
      ]
    },
    {
      title: '💯 Подготовься получать максимум за решение задач',
      targets: [
        {
          lessonTitle: 'Задание 27. Электродинамика, квантовая физика',
          mark: 1
        },
        {
          lessonTitle: 'Задание 28. Механика - квантовая физика',
          mark: 3
        },
        {
          lessonTitle: 'Задание 29. Механика (расчетная задача)',
          mark: 3
        },
        {
          lessonTitle: 'Задание 30. Молекулярная физика (расчетная задача)',
          mark: 3
        },
        {
          lessonTitle: 'Задание 31. Электродинамика (расчетная задача)',
          mark: 3
        },
        {
          lessonTitle: 'Задание 32. Электродинамика - квантовая физика (расчетная задача)',
          mark: 3
        }
      ]
    }
  ]
}
