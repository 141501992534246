import GoogleAnalytics, { trackPageView, trackEvent } from '@redux-beacon/google-analytics'
import { createMiddleware } from 'redux-beacon'
import combineEvents from '@redux-beacon/combine-events'
import logger from '@redux-beacon/logger'

import { LOCATION_CHANGE } from 'connected-react-router'
import { AccountActionTypes } from 'store/account/types'
import { ScheduleActionTypes } from 'store/schedule/types'
import { SubscriptionActionTypes } from 'store/subscription/types'

const userTracker = trackEvent((action, prevState, nextState) => {
  ;(window as any).ga('set', 'userId', action.payload.id)
  return { category: 'user_tracking', action: action.type }
})

const actionTracker = trackEvent((action, prevState, nextState) => {
  const regexp: RegExp = /@@(.+)\/(.+)/
  const match = regexp.exec(action.type)
  if (match && match.length === 3) {
    const category = match[1]
    const actionName = match[2]
    return { category: category, action: actionName }
  }
  return { category: 'unknown', action: action.type }
})

const eventMap = {
  [LOCATION_CHANGE]: trackPageView(action => ({
    page: action.payload.location.pathname
  })),
  [AccountActionTypes.LOGIN_SUCCESS]: userTracker,
  [AccountActionTypes.REGISTER_SUCCESS]: combineEvents(userTracker, actionTracker),
  [AccountActionTypes.LOGIN_TEACHER_SUCCESS]: userTracker,
  [AccountActionTypes.CHOOSE_SUBJECTS_SUCCESS]: actionTracker,

  [ScheduleActionTypes.CREATE_BOOKING_SUCCESS]: actionTracker,
  [ScheduleActionTypes.LESSON_LINK_OPENED]: actionTracker,

  [SubscriptionActionTypes.TARIFF_LINK_OPENED]: actionTracker
}

const ga = GoogleAnalytics()

const gaMiddleware = createMiddleware(eventMap, ga, { logger })

export { gaMiddleware }
