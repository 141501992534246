import { action } from 'typesafe-actions'
import { PageUrls } from 'utils/constants'
import { ScheduleActionTypes, BookingData, LessonData, BookingSuccessResponse } from './types'

export const fetchRequest = () => action(ScheduleActionTypes.FETCH_REQUEST)
export const fetchSuccess = (data: BookingData) => action(ScheduleActionTypes.FETCH_SUCCESS, data)
export const fetchError = (message: string) => action(ScheduleActionTypes.FETCH_ERROR, message)

export const openDialog = (bookingId: string, lessonId: string) => action(ScheduleActionTypes.OPEN_DIALOG, { bookingId, lessonId })
export const closeDialog = () => action(ScheduleActionTypes.CLOSE_DIALOG)

export const fetchLessonRequest = () => action(ScheduleActionTypes.FETCH_LESSONS_REQUEST)
export const fetchLessonSuccess = (data: LessonData) => action(ScheduleActionTypes.FETCH_LESSONS_SUCCESS, data)
export const fetchLessonError = (message: string) => action(ScheduleActionTypes.FETCH_LESSONS_ERROR, message)

export const selectDate = (id: number) => action(ScheduleActionTypes.SELECT_DATE, id)
export const changeDateRange = (shift: number) => action(ScheduleActionTypes.CHANGE_DATE_RANGE, shift)

export const createBookingRequest = (lessonId: string) => action(ScheduleActionTypes.CREATE_BOOKING_REQUEST, { lesson: lessonId })
export const createBookingSuccess = (data: BookingSuccessResponse) => action(ScheduleActionTypes.CREATE_BOOKING_SUCCESS, data)
export const createBookingError = (message: string) => action(ScheduleActionTypes.CREATE_BOOKING_ERROR, message)

export const cancelBookingRequest = (bookingId: string) => action(ScheduleActionTypes.CANCEL_BOOKING_REQUEST, bookingId)
export const cancelBookingSuccess = () => action(ScheduleActionTypes.CANCEL_BOOKING_SUCCESS)
export const cancelBookingError = (message: string) => action(ScheduleActionTypes.CANCEL_BOOKING_ERROR, message)

export const lessonLinkOpened = () => action(ScheduleActionTypes.LESSON_LINK_OPENED)

export const searchLesson = (topic: string, subject: string) => `${PageUrls.ScheduleApiSearch}?topic=${topic}&subject=${subject}`
