import { Reducer } from 'redux'
import { translations } from 'i18n/rus'
import { PageUrls } from 'utils/constants'
import { LOCATION_CHANGE } from 'connected-react-router'
import { AccountActionTypes, AccountState, LoginState, ChooseSubjectsState, RegistrationState, AccountContext } from './types'

const loginInitialState = {
  data: {},
  errors: undefined,
  loading: false
}

const subjectsInitialState = {
  data: { subjects: [] },
  errors: undefined,
  loading: false
}

const registrationInitialState = {}

const contextInitialState: AccountContext = {
  headerText: translations.buySubscription,
  headerLink: '',
  scheduleLink: '',
  logoutLink: ''
}

export const initialState: AccountState = {
  context: contextInitialState,
  login: loginInitialState,
  subjects: subjectsInitialState,
  registration: registrationInitialState
}

const loginReducer: Reducer<LoginState> = (state = loginInitialState, action) => {
  switch (action.type) {
    case AccountActionTypes.LOGIN_REQUEST:
    case AccountActionTypes.LOGIN_TEACHER_REQUEST:
    case AccountActionTypes.REGISTER_REQUEST: {
      return { ...state, loading: true }
    }
    case AccountActionTypes.LOGIN_SUCCESS:
    case AccountActionTypes.LOGIN_TEACHER_SUCCESS:
    case AccountActionTypes.REGISTER_SUCCESS: {
      if (action.payload.token) localStorage.setItem('token', action.payload.token)
      return { ...state, loading: false, data: { info: action.payload } }
    }
    case AccountActionTypes.LOGIN_ERROR:
    case AccountActionTypes.LOGIN_TEACHER_ERROR:
    case AccountActionTypes.REGISTER_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    case AccountActionTypes.LOGOUT: {
      localStorage.removeItem('token')
      return { ...state, data: {} }
    }

    default: {
      return state
    }
  }
}

// TODO this pattern is highly repetitive, try to create a common reducer for that
const subjectsReducer: Reducer<ChooseSubjectsState> = (state = subjectsInitialState, action) => {
  switch (action.type) {
    case AccountActionTypes.CHOOSE_SUBJECTS_REQUEST: {
      return { ...state, loading: true }
    }
    case AccountActionTypes.CHOOSE_SUBJECTS_SUCCESS: {
      return { ...state, loading: false, data: action.payload }
    }
    case AccountActionTypes.CHOOSE_SUBJECTS_ERROR: {
      return { ...state, loading: false, errors: action.payload }
    }

    default: {
      return state
    }
  }
}

const registrationReducer: Reducer<RegistrationState> = (state = registrationInitialState, action) => {
  switch (action.type) {
    case AccountActionTypes.UPLOAD_AVATAR_SUCCESS: {
      return { ...state, avatar: action.payload.avatar.id }
    }

    case AccountActionTypes.REGISTER_SUCCESS: {
      return { ...state, isRegistrationFinished: action.payload.isRegistrationFinished }
    }

    case AccountActionTypes.REGISTER_ERROR: {
      return { ...state, registrationFailed: true }
    }

    case AccountActionTypes.RESET_REGISTRATION: {
      return {}
    }

    default: {
      return state
    }
  }
}

const teacherContext = {
  headerText: translations.createLessonButton,
  headerLink: PageUrls.TeacherCreate,
  scheduleLink: PageUrls.TeacherSchedule,
  logoutLink: PageUrls.LoginTeacher
}

const studentContext = {
  headerText: translations.buySubscription,
  headerLink: PageUrls.Subscription,
  scheduleLink: PageUrls.ScheduleBooking,
  logoutLink: PageUrls.LoginNoParams
}

const pathToStateMapper = (path: string) => {
  if (path.startsWith(PageUrls.TeacherPrefix) || path === PageUrls.LoginTeacher) {
    return teacherContext
  }
  return studentContext
}

const contextReducer: Reducer<AccountContext> = (state = contextInitialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return pathToStateMapper(action.payload.location.pathname)
    }

    default: {
      return state
    }
  }
}

const reducer: Reducer<AccountState> = (state = initialState, action) => {
  return {
    context: contextReducer(state.context, action),
    login: loginReducer(state.login, action),
    subjects: subjectsReducer(state.subjects, action),
    registration: registrationReducer(state.registration, action)
  }
}

export { reducer as accountReducer }
