import * as React from 'react'
import { RouteComponentProps, Route, Switch } from 'react-router-dom'

import { LoginRequired } from 'utils/viewHelpers'
import ScheduleIndexPage from './schedule/index'
import ScheduleBookingPage from './schedule/booking'
import ScheduleSearchPage from './schedule/search'

// TODO fetch on route enter, but only if not too recent update
const SchedulePage: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <LoginRequired>
      <Switch>
        <Route exact path={`${match.path}/`} component={ScheduleIndexPage} />
        <Route exact path={`${match.path}/booking/:name?`} component={ScheduleBookingPage} />
        <Route exact path={`${match.path}/search/:topic/:subject`} component={ScheduleSearchPage} />
        {/* <Route path={`${match.path}/:name`} component={ShowSchedulePage} /> */}
      </Switch>
    </LoginRequired>
  )
}

export default SchedulePage
