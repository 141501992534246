import { styled, fade } from '@material-ui/core/styles'
import { ButtonBase, Grid, Button } from '@material-ui/core'
import { ButtonBaseProps } from '@material-ui/core/ButtonBase'
import React from 'react'

export const DateSelectionControl = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: 10,
  [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
    marginTop: 20
  }
}))

export const DateSelectionList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  border: `1px solid ${theme.colors.borders}`,
  borderRadius: 5
}))

const OutlinedButtonBase = ({ ...props }: ButtonBaseProps) => <ButtonBase focusRipple {...props} />

export const DateSelectionItem = styled(OutlinedButtonBase)(({ theme }) => ({
  minWidth: '70px',
  height: '60px',
  flex: 1,
  display: 'block',

  color: theme.colors.brand,
  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.colors.borders}`
  }
}))

export const DateSelectionItemGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: 5,
  height: '100%',
  justifyContent: 'space-between',
  '&:hover': {
    background: fade(theme.colors.brand, 0.08),
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  }
}))

export const ArrowButton = styled(Button)(() => ({
  background: 'transparent',
  textTransform: 'none',
  height: '100%',
  minWidth: 35,
  padding: 0
}))
