import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { fetchHandler, HttpVerb, endpointWithId, endpointWithPayload } from 'store/common/sagas'
import { PageUrls } from 'utils/constants'
import { push } from 'connected-react-router'
import { TeacherActionTypes } from './types'
import {
  fetchError,
  fetchSuccess,
  fetchPastError,
  fetchPastSuccess,
  fetchSubjectsError,
  fetchSubjectsSuccess,
  createLessonError,
  createLessonSuccess,
  loadEditLessonError,
  loadEditLessonSuccess,
  editLessonError,
  editLessonSuccess,
  cancelLessonError,
  cancelLessonSuccess
} from './actions'

function* watchFetchRequest() {
  yield takeLatest(
    TeacherActionTypes.FETCH_REQUEST,
    fetchHandler({
      onError: [fetchError],
      onSuccess: [fetchSuccess],
      apiEndpoint: PageUrls.TeacherApiSchedule
    })
  )
}

function* watchFetchPastLessonsRequest() {
  yield takeLatest(
    TeacherActionTypes.FETCH_PAST_REQUEST,
    fetchHandler({
      onError: [fetchPastError],
      onSuccess: [fetchPastSuccess],
      apiEndpoint: PageUrls.TeacherApiPastLessons
    })
  )
}

function* watchFetchSubjectsRequest() {
  yield takeLatest(
    TeacherActionTypes.FETCH_SUBJECTS_REQUEST,
    fetchHandler({
      onError: [fetchSubjectsError],
      onSuccess: [fetchSubjectsSuccess],
      apiEndpoint: PageUrls.TeacherApiSubjects
    })
  )
}

const redirectSchedule = () => push(PageUrls.TeacherSchedule)

function* watchCreateLessonRequest() {
  yield takeEvery(
    TeacherActionTypes.CREATE_LESSON_REQUEST,
    fetchHandler({
      onError: [createLessonError],
      onSuccess: [createLessonSuccess, redirectSchedule],
      apiEndpoint: PageUrls.TeacherApiCreate,
      httpVerb: HttpVerb.Post
    })
  )
}

function* watchLoadEditLessonRequest() {
  yield takeLatest(
    TeacherActionTypes.LOAD_EDIT_LESSON_REQUEST,
    fetchHandler({
      onError: [loadEditLessonError],
      onSuccess: [loadEditLessonSuccess],
      apiEndpoint: PageUrls.TeacherApiGetLesson,
      apiEndpointSelector: endpointWithPayload
    })
  )
}

function* watchEditLessonRequest() {
  yield takeEvery(
    TeacherActionTypes.EDIT_LESSON_REQUEST,
    fetchHandler({
      onError: [editLessonError],
      onSuccess: [editLessonSuccess, redirectSchedule],
      apiEndpoint: PageUrls.TeacherApiCreate,
      httpVerb: HttpVerb.Patch,
      apiEndpointSelector: endpointWithId
    })
  )
}

function* watchCancelLessonRequest() {
  yield takeEvery(
    TeacherActionTypes.CANCEL_LESSON_REQUEST,
    fetchHandler({
      onError: [cancelLessonError],
      onSuccess: [cancelLessonSuccess, redirectSchedule],
      apiEndpoint: PageUrls.TeacherApiCreate,
      httpVerb: HttpVerb.Delete,
      apiEndpointSelector: endpointWithPayload
    })
  )
}

function* teacherSaga() {
  yield all([
    fork(watchFetchRequest),
    fork(watchFetchPastLessonsRequest),
    fork(watchFetchSubjectsRequest),
    fork(watchCreateLessonRequest),
    fork(watchLoadEditLessonRequest),
    fork(watchEditLessonRequest),
    fork(watchCancelLessonRequest)
  ])
}

export default teacherSaga
