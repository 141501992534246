import React, { useState } from 'react'
import {
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepContent
} from '@material-ui/core'

import { Subject } from 'store/common/types'
import { MarathonMap, GoalTarget, isLessonTarget, isPracticeTarget, isCustomTarget } from 'store/marathon/types'
import { green } from '@material-ui/core/colors'
import DoneIcon from '@material-ui/icons/Done'
import { PageUrls } from 'utils/constants'
import { getProgressLevel, getRatingLinearProgress, StyledListItemIcon, TextLink } from './style'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: '100vh' // needed because of the 90vh wrapper that breaks it in Safari
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    actionsContainer: {
      marginBottom: theme.spacing(2)
    },
    resetContainer: {
      padding: theme.spacing(1)
    },
    stepLabel: {
      width: '100%',
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        width: 'auto'
      }
    },
    stepLabelText: {
      fontSize: 16,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        fontSize: 24
      }
    },
    buttonColor: {
      color: theme.colors.brand
    }
  })
)

function declineWordEnding(n: number, form1: string, form2: string, form5: string) {
  if (n == 1) return form1
  if (n >= 2 && n < 5) return form2
  return form5
}
function getStepContent(targets: GoalTarget[], subjectId: string) {
  const progressLevel0 = getProgressLevel(0)
  const Progress0 = getRatingLinearProgress(progressLevel0)
  return (
    <List dense>
      {targets.map(target => (
        <>
          <ListItem disableGutters>
            {/* {point.completed ? <DoneIcon style={{ color: green[400] }} /> : } */}
            <StyledListItemIcon>
              <></>
            </StyledListItemIcon>
            <ListItemText>
              {isLessonTarget(target) ? (
                <>
                  Пройди урок{' '}
                  <TextLink href={`${PageUrls.ScheduleSearch}/${target.lessonTitle}/${subjectId}`}>
                    <b>«{target.lessonTitle}»</b>{' '}
                  </TextLink>
                  {target.mark ? (
                    <>
                      ({target.mark} {declineWordEnding(target.mark, 'балл', 'балла', 'баллов')})
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {isPracticeTarget(target) ? (
                <>
                  Добей общий средний балл по заданиям до <b>{target.targetPoints}</b> 💪
                </>
              ) : (
                <></>
              )}
              {isCustomTarget(target) ? target.customTitle : <></>}
            </ListItemText>
          </ListItem>
          {isPracticeTarget(target) ? (
            <ListItem disableGutters>
              <StyledListItemIcon>
                <></>
              </StyledListItemIcon>
              <ListItemText>
                Текущий балл <b>{0}</b>
                {/* (point.currRate / point.rate) * 100 */}
                <Progress0 variant="determinate" color="secondary" value={0} />
              </ListItemText>
            </ListItem>
          ) : (
            <></>
          )}
        </>
      ))}
    </List>
  )
}

export const MarathonStepper: React.FC<{ marathonMap: MarathonMap; subjectId: string }> = ({ marathonMap, subjectId }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = marathonMap.goals

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const isStepComplete = (step: number) => {
    return false
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.resetContainer} nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((goal, index) => (
          <Step key={goal.title}>
            <StepButton onClick={handleStep(index)} completed={isStepComplete(index)}>
              <StepLabel className={classes.stepLabel}>
                <span className={classes.stepLabelText}>{goal.title}</span>
              </StepLabel>
            </StepButton>
            <StepContent>{getStepContent(goal.targets, subjectId)}</StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
