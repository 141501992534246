import * as React from 'react'
import { useEffect, useState } from 'react'
import { translations } from 'i18n/rus'
import Page from 'components/common/layout/Page'
import { Container, Grid, Checkbox, useMediaQuery, Theme, makeStyles, createStyles } from '@material-ui/core'
import { StyledH2, StyledButton } from 'components/common'
import { useSelector, useDispatch } from 'react-redux'
import { selectSubjects } from 'store/account/selectors'
import { chooseSubjectsRequest, submitSubjectsRequest } from 'store/account/actions'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { green } from '@material-ui/core/colors'
import { maxSubjectsToSelect } from 'utils/constants'
import { StyledFormControlLabel } from './style'

const useStyles = makeStyles(theme =>
  createStyles({
    unchecked: {
      color: theme.colors.brand
    },
    disabled: {
      color: theme.colors.borders
    },
    checked: {
      color: green[400]
    },
    margin: {
      marginTop: 20
    },
    nextButton: {
      fontSize: 15,
      [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
        fontSize: 22
      }
    }
  })
)

const ChooseSubjectsPage: React.FC = () => {
  const classes = useStyles()
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(chooseSubjectsRequest())
  }, [dispatch])
  const subjects = useSelector(selectSubjects)
  const checkedOnlyCount = Object.entries(checkedItems).filter(([k, v]) => v).length
  const maxItemsLimitReached = checkedOnlyCount >= maxSubjectsToSelect
  const minItemsSelected = checkedOnlyCount > 0

  const handleCheck = (itemId: string) => {
    const value: boolean | undefined = checkedItems[itemId]
    setCheckedItems({ ...checkedItems, [itemId]: !value })
  }

  const handleNextButton = () => {
    const checkedItemsOnly = Object.entries(checkedItems)
      .filter(([k, v]) => v)
      .map(([k, v]) => k)
    if (minItemsSelected) dispatch(submitSubjectsRequest({ subjects: checkedItemsOnly }))
  }

  return (
    <Page>
      <Container>
        <Grid alignItems="center" justify="center" direction="column" container>
          <Grid>
            <StyledH2>{translations.chooseSubjectsHeader}</StyledH2>
            <Grid className={classes.margin} container spacing={3}>
              {subjects.map(s => {
                const checked: boolean = checkedItems[s.id]
                return (
                  <Grid item xs={mobile ? 6 : 4}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => handleCheck(s.id)}
                          disabled={!checked && (!s.isActive || maxItemsLimitReached)}
                          icon={
                            <AddCircleIcon
                              fontSize={mobile ? 'default' : 'large'}
                              className={s.isActive && !maxItemsLimitReached ? classes.unchecked : classes.disabled}
                            />
                          }
                          checkedIcon={<CheckCircleIcon fontSize={mobile ? 'default' : 'large'} className={classes.checked} />}
                        />
                      }
                      label={`${s.title} ${s.emoji}`}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Grid className={classes.margin} container justify="center">
              <StyledButton disabled={!minItemsSelected} onClick={handleNextButton} className={classes.nextButton}>
                {translations.chooseSubjectsButton}
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ChooseSubjectsPage
