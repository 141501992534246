import { createStyles, Grid, makeStyles } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';

import { HeaderStyledButton, StyledLink, TextLink } from 'components/common';
import { translations } from 'i18n/rus';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccount, selectAccountContext } from 'store/account/selectors';
import { PageUrls } from 'utils/constants';

import { push } from 'connected-react-router';

import { ReactComponent as Logo } from 'static/img/logo.svg';
import { logout } from 'store/account/actions';
import { isLoggedIn } from 'store/account/types';
import { getAvatarLink } from 'utils/api';
import { getNameInitials } from 'utils/helpers';
import { StyledAppBar, StyledAvatar, StyledMenu, StyledToolbar } from './style';

interface AccountMenuProps {
    avatar: string;
    userName: string;
}

const AccountMenu: React.SFC<AccountMenuProps> = ({ avatar, userName }) => {
    const dispatch = useDispatch();
    const avatarLink = getAvatarLink(avatar);
    const avatarRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const handleOnClick = () => setOpen(!open);
    const handleClose = () => setOpen(false);
    const logoutHandler = () => {
        dispatch(logout());
    };

    return (
        <div>
            <StyledAvatar ref={avatarRef} src={avatarLink} tabIndex={0} aria-haspopup="true" onClick={handleOnClick}>
                {getNameInitials(userName)}
            </StyledAvatar>
            <StyledMenu
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={avatarRef.current}
                keepMounted={true}
                open={open}
                onClose={handleClose}
            >
                <MenuItem>
                    <span onClick={() => dispatch(push(PageUrls.ScheduleIndex))}>
                        <ListItemText primary={translations.scheduleMenu} onClick={handleClose} />
                    </span>
                </MenuItem>
                <MenuItem>
                    <span onClick={() => dispatch(push(PageUrls.ScheduleBooking))}>
                        <ListItemText primary={translations.bookingMenu} onClick={handleClose} />
                    </span>
                </MenuItem>
                <MenuItem>
                    <span onClick={() => dispatch(push(PageUrls.Marathon))}>
                        <ListItemText primary={translations.marathonMenu} onClick={handleClose} />
                    </span>
                </MenuItem>
                <MenuItem>
                    <span onClick={logoutHandler}>
                        <ListItemText primary={translations.logout} onClick={handleClose} />
                    </span>
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        logo: {
            width: 100,
            [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
                width: 150,
            },
        },
        headerLogin: {
            marginLeft: 45, // adjust so the middle button in navbar is centered
            [`@media (min-width: ${theme.breakpointsCustom.sm})`]: {
                marginLeft: 100, // adjust so the middle button in navbar is centered
            },
        },
    }),
);

const Header: React.SFC = () => {
    const classes = useStyles();
    const context = useSelector(selectAccountContext);
    const account = useSelector(selectAccount);
    const dispatch = useDispatch();
    const { scheduleLink, headerText } = context;

    return (
        <StyledAppBar position="static" elevation={0}>
            <StyledToolbar>
                <Grid justify="space-between" alignItems="center" direction="row" container={true}>
                    <StyledLink to={scheduleLink}>
                        <Grid container={true}>
                            <Logo className={classes.logo} />
                        </Grid>
                    </StyledLink>
                    {isLoggedIn(account.data) ? (
                        <HeaderStyledButton onClick={() => dispatch(push(context.headerLink))}>{headerText}</HeaderStyledButton>
                    ) : (
                        <></>
                    )}

                    {isLoggedIn(account.data) ? (
                        <AccountMenu avatar={account.data.info.avatar} userName={account.data.info.name} />
                    ) : (
                        <TextLink className={classes.headerLogin} to={PageUrls.LoginNoParams}>
                            {translations.loginButton}
                        </TextLink>
                    )}
                </Grid>
            </StyledToolbar>
        </StyledAppBar>
    );
};
export default Header;
