export enum NotificationActionType {
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
}

export type Notification = {
  key: number
  message: string
  options: any
  dismissed: boolean
}

export type NotificationState = Notification[]
