import { createSelector, OutputSelector } from 'reselect'
import { lessonSorter, dateKeyWithoutTime, dayDiffWithToday } from 'utils/helpers'
import { ApplicationState } from '..'
import { BookingData, LessonData, BookingState, IndexState } from './types'
import { Subject, Teacher, Lesson, BookedLesson } from '../common/types'

export interface AuxiliaryDataMappings {
  subjectIds: string[]
  subjectsById: Record<string, Subject>
  teachersById: Record<string, Teacher>
  lessonsBySubjectId: Record<string, Lesson[]>
}

export interface IndexDataMappings extends AuxiliaryDataMappings {
  allSubjectsAreBooked: boolean
}

export interface BookingDataMappings extends AuxiliaryDataMappings {
  bookedSubjectsById: Record<string, BookedLesson>
}

// string is date without time
export type BookingByDateMappings = Record<string, BookingDataMappings>

const arrayToDictionary = <T>(reducer: (s: T) => any, array: T[]): Record<number | string, T> =>
  array.reduce((obj: Record<number | string, T>, item: T) => {
    obj[reducer(item)] = item
    return obj
  }, {})

const arrayToDictionaryOfArrays = <T>(reducer: (s: T) => any, array: T[]): Record<string, T[]> => {
  const mapping: Record<string, T[]> = {}
  array.forEach(item => {
    const key = reducer(item)
    if (!mapping[key]) mapping[key] = [item]
    else mapping[key].push(item)
  })
  return mapping
}

const fillSubjectMapping = <T extends { subject: string }>(array: T[], subjects: string[]): Record<string, T[]> => {
  const mapping: Record<string, T[]> = {}
  for (const s of subjects) {
    mapping[s] = []
  }
  array.forEach(item => {
    mapping[item.subject].push(item)
  })
  return mapping
}

const sortRecordByKey = <T>(array: Record<string, T>) => {
  const ordered: T[] = []
  Object.keys(array)
    .sort()
    .forEach(function(key) {
      ordered.push(array[key])
    })
  return ordered
}

// TODO refactor this
const bookingDataToIdMappings = (bookingData: BookingData): IndexDataMappings => {
  const sortedLessons = bookingData.bookings.sort(lessonSorter)
  const subjectIds = bookingData.subjects.map(s => s.id)
  const lessonsBySubjectId = fillSubjectMapping(sortedLessons, subjectIds)
  lessonsBySubjectId[0] = sortedLessons
  const allSubjectsAreBooked = bookingData.subjects.length <= bookingData.bookings.length

  return {
    subjectIds,
    subjectsById: arrayToDictionary(s => s.id, bookingData.subjects),
    teachersById: arrayToDictionary(t => t.id, bookingData.teachers),
    lessonsBySubjectId,
    allSubjectsAreBooked
  }
}

const lessonNoDate = (lessonData: LessonData): BookingDataMappings => {
  const subjectIds = lessonData.subjects.map(s => s.id)
  return {
    subjectIds,
    subjectsById: arrayToDictionary(s => s.id, lessonData.subjects),
    teachersById: arrayToDictionary(t => t.id, lessonData.teachers),
    lessonsBySubjectId: fillSubjectMapping([], subjectIds),
    bookedSubjectsById: arrayToDictionary(b => b.subject, lessonData.bookedLessons)
  }
}

const lessonDataByDateMappings = (lessonData: LessonData): BookingByDateMappings => {
  function lessonsToIdMappings(ld: LessonData, lessons: Lesson[]): BookingDataMappings {
    const sortedLessons = lessons.sort(lessonSorter)
    const subjectIds = ld.subjects.map(s => s.id)
    const lessonsBySubjectId = fillSubjectMapping(sortedLessons, subjectIds)
    lessonsBySubjectId[0] = sortedLessons

    return {
      subjectIds,
      subjectsById: arrayToDictionary(s => s.id, ld.subjects),
      teachersById: arrayToDictionary(t => t.id, ld.teachers),
      lessonsBySubjectId,
      bookedSubjectsById: arrayToDictionary(b => b.subject, ld.bookedLessons)
    }
  }

  const lessonsByDate = arrayToDictionaryOfArrays(
    s => s.dateKey,
    lessonData.lessons.map(lesson => ({ ...lesson, dateKey: dateKeyWithoutTime(lesson.scheduledTime) }))
  )

  const lessonByDateWithMappings: BookingByDateMappings = {}
  // eslint-disable-next-line array-callback-return
  Object.keys(lessonsByDate).map((key, _) => {
    lessonByDateWithMappings[key] = lessonsToIdMappings(lessonData, lessonsByDate[key])
  })
  lessonByDateWithMappings.TEMP = lessonNoDate(lessonData)

  return lessonByDateWithMappings
}

export const selectIndexState = createSelector(
  (state: ApplicationState) => state.schedule,
  schedule => schedule.index
)

export const selectScheduleIndexPageState = createSelector(
  selectIndexState,
  index => ({ ...index, data: bookingDataToIdMappings(index.data) })
)

export const selectDialogState = createSelector(
  (state: ApplicationState) => state.schedule,
  schedule => schedule.dialogState
)

export const selectIndexDialogData = createSelector(
  selectIndexState,
  index => ({
    lessonsById: arrayToDictionary(l => l.id, index.data.bookings),
    teachersById: arrayToDictionary(t => t.id, index.data.teachers)
  })
)

export const selectBookingState = createSelector(
  (state: ApplicationState) => state.schedule,
  schedule => schedule.booking
)

export const selectScheduleBookingPageStateByDate = createSelector(
  selectBookingState,
  booking => ({
    booking,
    bookingByDate: lessonDataByDateMappings(booking.data)
  })
)

export const selectMinimalDate = createSelector(
  selectScheduleBookingPageStateByDate,
  bookingWithDateMap => {
    const bookingByDate = sortRecordByKey(bookingWithDateMap.bookingByDate).slice(0, 7)

    // eslint-disable-next-line no-restricted-syntax
    for (const booking of bookingByDate) {
      const firstSubjectId = booking.subjectIds[0]
      if (booking.lessonsBySubjectId[firstSubjectId] && booking.lessonsBySubjectId[firstSubjectId].length !== 0) {
        const firstLesson = booking.lessonsBySubjectId[firstSubjectId][0]
        const dayDiff = dayDiffWithToday(firstLesson.scheduledTime)

        return dayDiff < 7 && dayDiff >= 0 ? dayDiff : 0
      }
    }

    return 0
  }
)

export const selectScheduleBookingPageState = createSelector(
  selectScheduleBookingPageStateByDate,
  bookingWithDateMap => {
    const selectedDateIdx = bookingWithDateMap.booking.dateSelectorState.selectedDate
    const selectedDate = bookingWithDateMap.booking.dateSelectorState.displayedDates[selectedDateIdx]
    // TODO temp solution
    const data = bookingWithDateMap.bookingByDate[dateKeyWithoutTime(selectedDate)] || bookingWithDateMap.bookingByDate.TEMP
    return { ...bookingWithDateMap.booking, data }
  }
)

export const selectBookedDialogData = createSelector(
  selectBookingState,
  booking => ({
    lessonsById: arrayToDictionary(l => l.id, booking.data.lessons),
    teachersById: arrayToDictionary(t => t.id, booking.data.teachers)
  })
)

type DialogDataSelectorTypeGeneric<T> = OutputSelector<
  ApplicationState,
  {
    lessonsById: Record<string, Lesson>
    teachersById: Record<string, Teacher>
  },
  (
    res: T
  ) => {
    lessonsById: Record<string, Lesson>
    teachersById: Record<string, Teacher>
  }
>
export type DialogDataSelectorType = DialogDataSelectorTypeGeneric<BookingState> | DialogDataSelectorTypeGeneric<IndexState>

export const selectDataSelectorState = createSelector(
  selectBookingState,
  booking => booking.dateSelectorState
)

export const selectChosenDate = createSelector(
  selectDataSelectorState,
  dateSelectorState => dateSelectorState.displayedDates[dateSelectorState.selectedDate]
)
