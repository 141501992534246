import * as React from 'react'
import { translations } from 'i18n/rus'
import Page from 'components/common/layout/Page'
import { Container, Grid, FormControl, makeStyles, createStyles, Typography, InputAdornment } from '@material-ui/core'
import { TextLink, StyledH2, BrandStyledButton } from 'components/common'
import { StyledTextField } from 'pages/teacher/style'
import { PageUrls, phonePrefix, phoneLength } from 'utils/constants'
import InputMask from 'react-input-mask'
import { useSelector, useDispatch } from 'react-redux'
import { selectAccount, selectRegistrationFailed } from 'store/account/selectors'
import { isLoggedIn, LoginAction } from 'store/account/types'
import { Redirect, RouteComponentProps } from 'react-router'
import { WhiteProgress } from 'components/common/data/Progress'
import { removeSpaces, removeSpacesAndUnderlines } from 'utils/helpers'
import * as moment from 'moment-timezone'
import { registerRequest } from 'store/account/actions'
import { useState } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    fullHeight: {
      height: '80vh' // - header size
    },
    textAdornment: {
      whiteSpace: 'nowrap'
    },
    progress: {
      marginLeft: '9.5px',
      marginRight: '9.5px'
    },
    registerHelper: {
      whiteSpace: 'nowrap'
    }
  })
)

interface LoginPageProps {
  loginAction: (phone: string, pass: string) => LoginAction
  showRegister: boolean
  loggedInRedirect: string
  phoneParam?: string
}

const LoginPage: React.FC<LoginPageProps> = ({ loginAction, showRegister, loggedInRedirect, phoneParam }) => {
  const classes = useStyles()
  const account = useSelector(selectAccount)
  const registrationError = useSelector(selectRegistrationFailed)
  const dispatch = useDispatch()

  const [login, setLogin] = useState(phoneParam ? phoneParam : '')
  const [loginInv, setLoginInv] = useState(false)
  const [pass, setPass] = useState('')
  if (isLoggedIn(account.data)) return <Redirect to={loggedInRedirect} />

  const handleLogin = () => {
    const parsedPhoneNumber = removeSpacesAndUnderlines(login)
    const loginValid = parsedPhoneNumber.length === phoneLength
    if (!loginValid) {
      setLoginInv(!loginValid)
      return
    }

    const phone = phonePrefix + parsedPhoneNumber
    if (registrationError) {
      dispatch(loginAction(phone, pass))
    } else {
      dispatch(
        registerRequest({
          phone,
          timezone: moment.tz.guess()
        })
      )
    }
  }

  return (
    <Page>
      <Container>
        <Grid className={classes.fullHeight} alignItems="center" justify="center" direction="column" container>
          <Grid>
            <StyledH2>{translations.loginHeader}</StyledH2>
            <FormControl fullWidth>
              <InputMask mask="999 999 99 99" value={login} onChange={e => setLogin(e.target.value)}>
                {() => (
                  <StyledTextField
                    id="login-required"
                    label={translations.loginPlaceholder}
                    margin="normal"
                    error={loginInv}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        // eslint-disable-next-line jsx-a11y/accessible-emoji
                        <InputAdornment className={classes.textAdornment} position="start">
                          🇷🇺 {phonePrefix}
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              </InputMask>
              {phoneParam || registrationError ? (
                <StyledTextField
                  id="pass-required"
                  label={translations.passwordPlaceholder}
                  value={pass}
                  type="password"
                  onChange={e => setPass(e.target.value)}
                  margin="normal"
                  variant="outlined"
                />
              ) : (
                <></>
              )}

              <Grid container justify="center">
                <BrandStyledButton onClick={handleLogin}>
                  {account.loading ? <WhiteProgress size={28} className={classes.progress} /> : translations.loginButton}
                </BrandStyledButton>
              </Grid>
              {account.errors ? (
                <Grid container justify="center">
                  <Typography color="error">{translations.loginError}</Typography>
                </Grid>
              ) : (
                <></>
              )}
              {/* {showRegister ? (
                <Grid container justify="center">
                  <Typography className={classes.registerHelper}>
                    {translations.notRegistered} <TextLink to={PageUrls.RegisterNoParams}>{translations.registerLink}</TextLink>
                  </Typography>
                </Grid>
              ) : (
                <></>
              )} */}
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default LoginPage
