import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import { ScheduleState } from './schedule/types'
import { TeacherState } from './teacher/types'
import { SubscriptionState } from './subscription/types'
import { AccountState } from './account/types'

import { scheduleReducer } from './schedule/reducer'
import { teacherReducer } from './teacher/reducer'
import { subscriptionReducer } from './subscription/reducer'
import { accountReducer } from './account/reducer'
import scheduleSaga from './schedule/sagas'
import subscriptionSaga from './subscription/sagas'
import teacherSaga from './teacher/sagas'
import accountSaga from './account/sagas'
import { NotificationState } from './notification/types'
import { notificationReducer } from './notification/reducer'

export interface ApplicationState {
  schedule: ScheduleState
  teacher: TeacherState
  subscription: SubscriptionState
  account: AccountState
  notifications: NotificationState
  router: RouterState
}

export const createRootReducer = (history: History) =>
  combineReducers({
    schedule: scheduleReducer,
    teacher: teacherReducer,
    subscription: subscriptionReducer,
    account: accountReducer,
    notifications: notificationReducer,
    router: connectRouter(history)
  })
export function* rootSaga() {
  yield all([fork(scheduleSaga), fork(teacherSaga), fork(subscriptionSaga), fork(accountSaga)])
}
