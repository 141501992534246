import * as React from 'react'
import { styled } from '@material-ui/core/styles'

interface RootProps {
  className?: string
}

const Root: React.SFC<RootProps> = ({ children }) => <Wrapper>{children}</Wrapper>

export default Root

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  backgroundColor: theme.colors.background,
  color: theme.colors.body,
  fontFamily: theme.fonts.body
}))
