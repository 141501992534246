import { NotificationActionType, Notification } from './types'

export const enqueueSnackbar = (notification: Notification) => {
  const key = notification.options && notification.options.key

  return {
    type: NotificationActionType.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  }
}

export const closeSnackbar = (key: number) => ({
  type: NotificationActionType.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
})

export const removeSnackbar = (key: number) => ({
  type: NotificationActionType.REMOVE_SNACKBAR,
  key
})
