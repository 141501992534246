import * as React from 'react'
import { translations } from 'i18n/rus'
import { createLessonRequest } from 'store/teacher/actions'
import { useDispatch } from 'react-redux'
import { LessonCreateEdit } from 'store/teacher/types'
import TeacherCreateEditLessonPage from './createEdit'

const TeacherCreateLessonPage: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <TeacherCreateEditLessonPage
      header={translations.createLessonHeader}
      saveEvent={(lesson: LessonCreateEdit) => dispatch(createLessonRequest(lesson))}
    />
  )
}

export default TeacherCreateLessonPage
